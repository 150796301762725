<template>
    <div class="content-wrapper overflow-visible">
    <div class="content pt-0">
        <div class="card mb-1">
        <div class="card-body">
            <div class="row  d-flex justify-content-between">
            <div class="col-md-auto">
                <h6 class="card-title mb-0">Status:
                
                <template v-if="row.ar_is_checkin == 'Y' && row.ar_is_verif_admisi == 'Y' && row.ar_is_finger == 'Y'">
                    
                    <template v-if="(row.ar_status <= $parent.sFisio) || (row.ar_status == 99 || row.ar_status == 98)">
                        <span :class="`badge ${row.ars_color}`">{{$parent.uppercaseWord(row.ars_name)||'-'}}</span>
                    </template>
                    <template v-else>
                        <template v-if="row.ar_status == $parent.sPPA">
                            <span v-if="row.ar_is_ppa_fisio == 'Y' && row.ar_is_done_ppa_fisio !== 'Y'"
                                :class="'badge bg-blue-600 text-white mb-1'">Fisioterapi</span>
                            <span v-if="row.ar_is_ppa_radiologi == 'Y' && row.ar_is_done_ppa_radiologi !== 'Y'"
                                :class="'badge bg-blue-600 text-white mb-1'">Radiologi</span>
                            <span v-if="row.ar_is_ppa_lab == 'Y' && row.ar_is_done_ppa_lab !== 'Y'"
                                :class="'badge bg-blue-600 text-white mb-1'">Laboratorium</span>
                            <span v-if="row.ar_is_ppa_dietisien == 'Y' && row.ar_is_done_ppa_dietisien !== 'Y'"
                                :class="'badge bg-info text-white mb-1'">Dietisien</span>
                        </template>
                        <span v-else :class="`badge ${row.ars_color}`">{{$parent.uppercaseWord(row.ars_name)||'-'}}</span>
                    </template>

                   
                    
                    <span v-if="row.ar_is_belum_datang == 'Y'"
                        :class="`badge badge-info mt-1`">{{$parent.uppercaseWord('Belum Datang')||'-'}}</span>

                </template>

                <span class="badge badge-info mb-1" v-if="row.ar_is_checkin !== 'Y' && row.ar_is_finger !== 'Y'"> Belum Checkin </span>
                
                <span class="badge badge-info mb-1" v-if="row.ar_is_checkin == 'Y' && row.ar_is_finger !== 'Y'"> Belum Checkin </span>
                
                <span class="badge badge-info mb-1" v-if="row.ar_is_checkin !== 'Y' && row.ar_is_finger == 'Y'"> Belum Checkin </span>
                
                <span class="badge bg-warning" v-if="row.ar_is_verif_admisi !== 'Y'">Perlu Verifikasi</span>

                <span class="badge badge-purple ml-1" v-if="row.ar_is_fast_track == 'Y'">FAST TRACK</span>
                
                <span class="badge bg-danger ml-1" v-if="row.ar_is_void == 'Y'">VOID</span>
                </h6>
                <p class="mb-0 mt-1"><span class="text-success font-weight-semibold">{{row.ap_gol_usia||"-"}}</span><span v-if="row.arres_is_alat_bantu == 'Y'">, Menggunakan Alat
                Bantu ({{getConfigDynamic(mAlatBantu,row.arres_alat_bantu)||"-"}})</span></p>
            </div>
            <div class="col-md-auto">


                <router-link v-b-tooltip.hover="'Edit Data'" class="btn float-right btn-primary mr-1" :to="{name: $route.name, params: {pageSlug: row.arres_id}}">
                  <b><i class="icon-pencil7"></i></b>
                </router-link>
                
                <a href="javascript:;" 
                v-if="(row.ar_is_checkin == 'Y' && row.ar_is_finger && row.arres_jaminan == 3 && row.ar_is_finger_bpjs == 'Y' && rowPasien.ap_usia >= 17) || row.arres_jaminan != 3 || rowPasien.ap_usia < 17"
                v-b-tooltip.hover="'Cetak Nomor Antrian'" @click="cetakKartu" class="btn float-right btn-secondary mr-1">
                    <b><i class="icon-printer"></i></b>
                    
                </a>

                <a href="javascript:;" v-b-tooltip.hover="'Kirim Ke Whatsapp'"  v-if="row.arres_status == 1" @click="modalwa = true" class="btn float-right btn-success mr-1">
                    <b><i class="icon-paperplane"></i></b>
                   
                </a>

                <template v-if="row.ar_is_reg_penunjang != 'Y'">
                <a href="javascript:;" v-if="row.arres_jaminan == 3 && row.showSEP && row.ar_is_checkin == 'Y' && row.ar_is_finger == 'Y'" class="btn float-right btn-warning mr-1 btn-labeled btn-labeled-left" @click="addSEPDataKontrol">
                    <b><i class="icon-upload"></i></b>
                    Buat SEP
                </a>
                
                <router-link :to="{name: 'BPJSDataSEP', params: {pageSlug: row.idSEP}, query:{ detail: row.noSEP ? true : ''}}" v-if="row.arres_jaminan == 3 && !row.showSEP && row.ar_is_checkin == 'Y' && row.ar_is_finger == 'Y'" class="btn float-right btn-warning mr-1 btn-labeled btn-labeled-left">
                    <b><i class="icon-eye"></i></b>
                    <span v-if="row.noSEP"> Lihat SEP</span>
                    <span v-else>Buat SEP</span>
                </router-link>

                <a href="javascript:;" v-b-tooltip.hover="'Buat Ulang SEP'" @click="deleteSEP()" v-if="row.arres_jaminan == 3 && !row.showSEP && row.ar_is_checkin == 'Y' && row.ar_is_finger == 'Y' && !row.noSEP" class="btn float-right btn-info mr-1">
                    <b><i class="icon-upload"></i></b>
                    Buat Ulang SEP
                </a>
                </template>

                <a href="javascript:;" v-b-tooltip.hover="'Finger Print BPJS'" v-if="row.ar_is_checkin == 'Y' && row.ar_is_finger && row.arres_jaminan == 3 && row.ar_is_finger_bpjs != 'Y' && rowPasien.ap_usia >= 17" class="btn float-right btn-warning mr-1" @click="openPopupBPJS">
                    <b><i class="icon-point-up"></i></b>
                    
                </a>
                
                <a href="javascript:;" v-b-tooltip.hover="'Bypass Data'" v-if="row.ar_is_checkin != 'Y'" @click="bypassFinger(row)" class="btn float-right btn-secondary mr-1">
                    <b><i class="icon-arrow-up-right2"></i></b>
                    
                </a>
                
                <a href="javascript:;" v-b-tooltip.hover="'Verifikasi Asuransi'" @click="openVerif = true" 
                v-if="row.arres_status == 1 && row.ar_is_verif_admisi !== 'Y' && row.ar_is_checkin == 'Y' &&  row.ar_is_unverif_admisi != 'Y'" data-popup="tooltip" title="Verifikasi Asuransi" data-toggle="modal"
                class="btn float-right mr-1 btn-warning">
                <b><i class="icon-check"></i></b>
                
                </a>
                
                <a href="javascript:;" v-b-tooltip.hover="'Void'" @click="openVoid = true" 
                v-if="row.ar_is_void !== 'Y'" data-popup="tooltip" title="Void" data-toggle="modal"
                class="btn float-right mr-1 btn-danger btn-labeled btn-labeled-left">
                <b><i class="icon-blocked"></i></b>
                    VOID
                </a>
                
            </div>
            </div>
        </div>
        </div>

        <div class="card mb-1">
        <div class="card-header bg-white">
            <h6 class="card-title font-weight-semibold">Data Reservasi  {{row.ar_reg_code}} - {{rowPasien.ap_fullname||"-"}}</h6>
        </div>
        <div class="card-body">
            <div class="row">
            <div class="col-md-2">
                <div class="wrap_qrcode">
                <img :src="$parent.uploader(row.ar_qr_code)" class="img-fluid" />
                </div>
            </div>
            <div class="col-md-7">
                <table class="table table-borderless table-sm text-uppercase">
                <tbody>
                    <tr>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>No. Rekam Medis</h4>
                        <p>{{row.ap_code||"-"}}</p>
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Cara Bayar</h4>
                        <span>{{getConfigDynamic(Config.mr.statusJaminan,row.arres_jaminan)||"-"}}</span><br/>
                        <span v-if="row.arres_jaminan == 2">{{row.mcp_name||"-"}}</span>
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Pemeriksaan </h4>
                        <span>{{getConfigDynamic(Config.mr.statusPemeriksaanOpt,row.arres_type)||"-"}}</span>
                        <p class="text-info d-block mb-0" v-if="row.arres_type=='Poli'">
                        {{row.mpo_name||"-"}}</p>
                        <p class="text-info d-block mb-0" v-else-if="row.arres_type=='Penunjang'">
                        {{getConfigDynamic(Config.mr.statusPenunjangOpt,row.arres_jenis_penunjang)||"-"}}</p>
                        </div>
                    </td>
                    </tr>
                    <tr>
                    <td>
                        <div class="result_tab">
                        <h4>Tanggal, Jam Periksa</h4>
                        <p>{{row.ar_reg_date | moment("DD MMM YYYY, HH:mm")}} WIB</p>
                        </div>
                    </td>
                    <td>
                        <div class="result_tab">
                        <h4>Diperiksa Oleh</h4>
                            <p v-if="row.arres_type == 'Poli'">{{row.bu_full_name||"-"}}</p>
                            <template v-else>
                                <p v-if="row.arres_jenis_penunjang == 'LAB'">Petugas Laboratorium</p>
                                <p v-else-if="row.arres_jenis_penunjang == 'RADIO'">Petugas Radiologi</p>
                                <p v-else-if="row.arres_jenis_penunjang == 'FISIO'">Petugas Fisioterapi</p>
                            </template>
                        </div>
                    </td>
                    <td>
                        <div class="result_tab">
                        <h4>Pasien Kecelakaan</h4>
                        <p>{{row.arres_is_kecelakaan == 'Y'?'Ya':'Tidak'}}</p>
                        <template v-if="row.arres_is_kecelakaan == 'Y' && !row.arres_file_kecelakaan">
                            <a href="javascript:;" @click="openUploadDoc = true" data-popup="tooltip" title="" data-toggle="modal"
                            data-target="#uploadKronologi" class="font-weight-semibold mb-2 border-bottom"
                            data-original-title="Upload Form Kronologi" aria-describedby="tooltip159899">
                            <i class="icon-plus22"></i> UPLOAD KRONOLOGI</a>
                            <br>
                            <a :href="$parent.uploader('SURAT_PERNYATAAN_KRONOLOGI_KEJADIAN.docx')" target="_blank"  data-popup="tooltip" title=""
                                class="font-weight-semibold border-bottom"
                            data-original-title="Upload Form Kronologi" aria-describedby="tooltip159899">
                            <i class="icon-download"></i> DOWNLOAD FORM</a>
                        </template>

                        <template v-if="row.arres_is_kecelakaan == 'Y' && row.arres_file_kecelakaan">
                            <a :href="$parent.uploader(row.arres_file_kecelakaan)" target="_blank"  data-popup="tooltip" title=""
                                class="font-weight-semibold border-bottom"
                            data-original-title="Lihat File Kecelakaan" aria-describedby="tooltip159899">
                            <i class="icon-download"></i>Lihat File Kecelakaan</a>
                        </template>

                        </div>
                    </td>
                    </tr>
                </tbody>
                </table>
            </div>
            <div class="col-md-3">
                <div class="queue_wrap">
                <p class="text-center">Nomor Antrean</p>
                <h3>{{row.ar_no_antrian||"-"}}</h3>
                <h4>{{row.mpo_name||"-"}}</h4>
                </div>
            </div>
            </div>
        </div>
        </div>
        <div class="card">
        <ul class="nav nav-tabs nav-tabs-highlight nav-justified mb-0">
            <li class="nav-item"><a href="javascript:;" @click="changeTab(1)" data-toggle="tab" data-target="#pasienTabInfo"
                :class="activeTab == 1 ? 'nav-link active' : 'nav-link'">Informasi Pasien</a></li>
            <li class="nav-item"><a href="javascript:;" @click="changeTab(2)" data-toggle="tab" data-target="#pasienTabPJ"
                :class="activeTab == 2 ? 'nav-link active' : 'nav-link'">Informasi Penanggung Jawab</a></li>
            <li class="nav-item"><a href="javascript:;" @click="changeTab(3)" data-toggle="tab" data-target="#pasienTabKW"
                :class="activeTab == 3 ? 'nav-link active' : 'nav-link'">Informasi Kewenangan</a></li>
            <li class="nav-item"><a href="javascript:;" @click="changeTab(4)" data-toggle="tab" data-target="#pasienTabKW"
                :class="activeTab == 4 ? 'nav-link active' : 'nav-link'">Dokumen</a></li>
        </ul>
        <div class="tab-content">
            
            <div :class="activeTab == 1 ? 'tab-pane fade show active' : 'tab-pane fade'" id="pasien">
                <div class="card-body">
                <table class="table table-bordered table-sm text-uppercase">
                    <tbody>
                    <tr>
                        <td width="33%">
                        <div class="result_tab">
                            <h4>No. Rekam Medis</h4>
                            <p>{{rowPasien.ap_code||"-"}}</p>
                        </div>
                        </td>
                        <td width="33%">
                        <div class="result_tab">
                            <h4>Nama</h4>
                            <p>{{rowPasien.ap_fullname||"-"}}</p>
                        </div>
                        </td>
                        <td width="33%">
                        <div class="result_tab">
                            <h4>NIK</h4>
                            <p>{{rowPasien.ap_nik}}</p>
                        </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        <div class="result_tab">
                            <h4>Tempat, Tanggal Lahir</h4>
                            <p>{{rowPasien.ap_pob||"-"}}, {{rowPasien.ap_dob_true | moment("DD MMM YYYY")}}</p>
                        </div>
                        </td>
                        <td>
                        <div class="result_tab">
                            <h4>Usia</h4>
                            <p>{{rowPasien.ap_usia_with_ket||"-"}}</p>
                        </div>
                        </td>
                        <td>
                        <div class="result_tab">
                            <h4>Kategori Usia</h4>
                            <p>{{rowPasien.ap_gol_usia||"-"}}</p>
                        </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        <div class="result_tab">
                            <h4>Jenis Kelamin</h4>
                            <p>{{rowPasien.cg_label||"-"}}</p>
                        </div>
                        </td>
                        <td>
                        <div class="result_tab">
                            <h4>Agama</h4>
                            <p v-if="rowPasien.ma_id !== 99999">{{rowPasien.ma_name||"-"}}</p>
                            <p v-else>{{rowPasien.ap_agama_text||"-"}}</p>
                        </div>
                        </td>
                        <td>
                        <div class="result_tab">
                            <h4>Suku</h4>
                            <p>{{rowPasien.mrsuk_name||"-"}}</p>
                        </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        <div class="result_tab">
                            <h4>Pekerjaan</h4>
                            <p v-if="rowPasien.mp_id !== 99999">{{rowPasien.mp_name||"-"}}</p>
                            <p v-else>{{rowPasien.ap_pekerjaan_text||"-"}}</p>
                        </div>
                        </td>
                        <td>
                        <div class="result_tab">
                            <h4>Pendidikan</h4>
                            <p>{{rowPasien.mpen_name||"-"}}</p>
                        </div>
                        </td>
                        <td>
                        <div class="result_tab">
                            <h4>Nama Ibu Kandung</h4>
                            <p>{{rowPasien.ap_nama_ibu||"-"}}</p>
                        </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        <div class="result_tab">
                            <h4>No. Telpon</h4>
                            <p>+62{{rowPasien.ap_phone_number||"-"}}</p>
                        </div>
                        </td>
                        <td colspan="2">
                        <div class="result_tab">
                            <h4>Email</h4>
                            <p v-if="rowPasien.ap_email">{{rowPasien.ap_email.trim()||"-"}}</p>
                            <p v-else> - </p>
                        </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        <div class="result_tab">
                            <h4>Status Pernikahan</h4>
                            <p v-if="rowPasien.ap_status_pernikahan == 1">Belum Kawin</p>
                            <p v-else-if="rowPasien.ap_status_pernikahan == 2">Kawin</p>
                            <p v-else-if="rowPasien.ap_status_pernikahan == 3">Cerai Hidup</p>
                            <p v-else-if="rowPasien.ap_status_pernikahan == 4">Cerai Mati</p>
                            <p v-else> - </p>
                        </div>
                        </td>
                        <td>
                        <div class="result_tab">
                            <h4>Bahasa yang Dikuasai</h4>
                            <p>{{rowPasien.ap_language||"-"}}</p>
                        </div>
                        </td>
                        <td>
                        <div class="result_tab">
                            <h4>Golongan Darah</h4>
                            <p v-if="rowPasien.ap_gol_darah">{{rowPasien.ap_gol_darah.trim()||"-"}}</p>
                            <p v-else> - </p>
                        </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3">
                        <div class="result_tab">
                            <h4>Alamat Domisili</h4>
                            <p>{{domisiliAddr||"-"}}</p>
                        </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3">
                        <div class="result_tab">
                            <h4>Alamat Sesuai KTP</h4>
                            <p>{{ktpAddr||"-"}}</p>
                        </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
                </div>
            </div>
            
            <div :class="activeTab == 2 ? 'tab-pane fade show active' : 'tab-pane fade'" id="penanggung-jawab">
                <div class="card-body">
                    <table class="table table-borderless table-sm text-uppercase">
                        <tbody>
                        <tr>
                            <td width="33%">
                            <div class="result_tab">
                                <h4>Hubungan Keluarga</h4>
                                <p>{{rowPasien.arpj_hubungan_keluarga_text||"-"}}</p>
                            </div>
                            </td>
                            <td width="33%">
                            <div class="result_tab">
                                <h4>Nama Penanggung Jawab</h4>
                                <p>{{rowPasien.arpj_fullname||"-"}}</p>
                            </div>
                            </td>
                            <td width="33%">
                            <div class="result_tab">
                                <h4>Pekerjaan </h4>
                                <p v-if="rowPasien.arpj_pekerjaan !== 99999">{{rowPasien.pekerjaan_pj||"-"}}</p>
                                <p v-else>{{rowPasien.arpj_pekerjaan_text||"-"}}</p>
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                            <div class="result_tab">
                                <h4>Jenis Kelamin</h4>
                                <p>{{rowPasien.gender_pj||"-"}}</p>
                            </div>
                            </td>
                            <td>
                            <div class="result_tab">
                                <h4>Agama </h4>
                                <p v-if="rowPasien.arpj_agama !== 99999">{{rowPasien.agama_pj||"-"}}</p>
                                <p v-else>{{rowPasien.arpj_agama_text||"-"}}</p>
                            </div>
                            </td>
                            <td>
                            <div class="result_tab">
                                <h4>No Handphone</h4>
                                <p>+62{{rowPasien.arpj_phone_number}}</p>
                            </div>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="3">
                            <div class="result_tab">
                                <h4>Alamat Domisili</h4>
                                <p>{{pjAddr||"-"}}</p>
                            </div>
                            </td>
                        </tr>

                        </tbody>
                    </table>
                </div>
            </div>
            
            <div :class="activeTab == 3 ? 'tab-pane fade show active' : 'tab-pane fade'" id="informasi-kewenangan">
                <div class="card-body">
                    <table class="table table-borderless table-sm text-uppercase">
                        <tbody>
                        <tr>
                            <td width="33%">
                            <div class="result_tab">
                                <h4>Hubungan Keluarga</h4>
                                <p>{{rowPasien.arki_hubungan_keluarga_text||"-"}}</p>
                            </div>
                            </td>
                            <td width="33%">
                            <div class="result_tab">
                                <h4>Nama Penanggung Jawab</h4>
                                <p>{{rowPasien.arki_fullname||"-"}}</p>
                            </div>
                            </td>
                            <td width="33%">
                            <div class="result_tab">
                                <h4>Pekerjaan </h4>
                                <p v-if="rowPasien.arki_pekerjaan !== 99999">{{rowPasien.pekerjaan_ki||"-"}}</p>
                                <p v-else>{{rowPasien.arki_pekerjaan_text||"-"}}</p>
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                            <div class="result_tab">
                                <h4>Jenis Kelamin</h4>
                                <p>{{rowPasien.gender_ki||"-"}}</p>
                            </div>
                            </td>
                            <td>
                            <div class="result_tab">
                                <h4>Agama </h4>
                                <p v-if="rowPasien.arki_agama !== 99999">{{rowPasien.agama_ki||"-"}}</p>
                                <p v-else>{{rowPasien.arki_agama_text||"-"}}</p>
                            </div>
                            </td>
                            <td>
                            <div class="result_tab">
                                <h4>No Handphone</h4>
                                <p>+62{{rowPasien.arki_phone_number}}</p>
                            </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            
            <div :class="activeTab == 4 ? 'tab-pane fade show active' : 'tab-pane fade'" id="dokumen">
            <div class="card-body">
                <table class="table table-borderless table-sm text-uppercase">
                <tbody>
                    <tr v-if="row.arres_jaminan != 3">
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Foto KTP/KK</h4>
                        <div class="d-flex align-items-center mt-1">
                            <div v-if="row.arres_file_ktp">
                                <a :href="$parent.uploader(row.arres_file_ktp)" data-popup="tooltip" title=""
                                    class="btn alpha-primary border-primary text-primary-800 btn-icon"
                                    data-original-title="Download"><i class="icon-download7
                                "></i></a>
                            </div>
                            <div v-else> - </div>
                        </div>
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Foto Pasien</h4>
                        <div class="d-flex align-items-center mt-1">
                            <div v-if="row.arres_file_foto_pasien">
                                <a :href="$parent.uploader(row.arres_file_foto_pasien)" data-popup="tooltip" title=""
                                    class="btn alpha-primary border-primary text-primary-800 btn-icon"
                                    data-original-title="Download"><i class="icon-download7
                                "></i></a>
                            </div>
                            <div v-else> - </div>
                        </div>
                        </div>
                    </td>
                    <td width="33%" v-if="row.arres_jaminan == 2">
                        <div class="result_tab">
                        <h4>Foto Kartu Asuransi</h4>
                        <div class="d-flex align-items-center mt-1">
                            <div v-if="row.arres_file_asuransi">
                            <a :href="$parent.uploader(row.arres_file_asuransi)" data-popup="tooltip" title=""
                                class="btn alpha-primary border-primary text-primary-800 btn-icon"
                                data-original-title="Download"><i class="icon-download7
                            "></i></a>
                            </div>
                            <div v-else> - </div>
                        </div>
                        </div>
                    </td>
                    </tr>
                    <tr v-else>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Foto Kartu BPJS</h4>
                        <div class="d-flex align-items-center mt-1">
                            <div v-if="row.arres_kartu_bpjs">
                            <a :href="$parent.uploader(row.arres_kartu_bpjs)" data-popup="tooltip" title=""
                                class="btn alpha-primary border-primary text-primary-800 btn-icon"
                                data-original-title="Download"><i class="icon-download7
                            "></i></a>
                            </div>
                            <div v-else> - </div>
                        </div>
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Foto Surat Rujukan</h4>
                        <div class="d-flex align-items-center mt-1">
                            <div v-if="row.arres_surat_rujukan">
                            <a :href="$parent.uploader(row.arres_surat_rujukan)" data-popup="tooltip" title=""
                                class="btn alpha-primary border-primary text-primary-800 btn-icon"
                                data-original-title="Download"><i class="icon-download7
                            "></i></a>
                            </div>
                            <div v-else> - </div>
                        </div>
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Foto Surat Kontrol</h4>
                        <div class="d-flex align-items-center mt-1">
                            <div v-if="row.arres_surat_kontrol">
                            <a :href="$parent.uploader(row.arres_surat_kontrol)" data-popup="tooltip" title=""
                                class="btn alpha-primary border-primary text-primary-800 btn-icon"
                                data-original-title="Download"><i class="icon-download7
                            "></i></a>
                            </div>
                            <div v-else> - </div>
                        </div>
                        </div>
                    </td>
                    </tr>

                    <tr v-if="row.arres_jaminan == 2">
                    <td width="33%">
                        <div class="result_tab">
                        <h4>No Polis</h4>
                        <span>{{row.arres_no_polis||"-"}}</span>
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>No Kartu Asuransi</h4>
                        <span>{{row.arres_no_kartu_asuransi||"-"}}</span>                        
                        </div>
                    </td>
                    </tr>
                </tbody>
                </table>
            </div>
            </div>
            
        </div>
        </div>
        <!-- /dashboard content -->
    </div>

    <b-modal body-class="p-0" id="formMedicalRecord" v-model="openVerif" size="md" hide-header hide-footer>
        <div class="p-0">
            <div class="modal-header">
                <h5 class="modal-title">Verifikasi Asuransi Pasien</h5>
                <button @click="openVerif=false" type="button" class="close" data-dismiss="modal">×</button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                <div class="modal-shout-icon mb-3 d-inline-block alpha-info text-info rounded-circle">
                    <i class="icon-check"></i>
                </div>
                <h6 class="mb-3">
                    Apakah Anda yakin asuransi Valid / Tidak Valid?
                </h6>
                <div>
                    <a href="javascript:;" @click="verif('Y')" class="mr-1 btn btn-success btn-labeled btn-labeled-left">
                    <b><i class="icon-checkmark"></i></b>
                    Valid
                    </a>
                    <a href="javascript:;" @click="verif('I')" data-dismiss="modal" class="mr-1 btn btn-outline-secondary">
                    Invalid
                    </a>
                </div>
                </div>
            </div>
        </div>
    </b-modal>

    <b-modal body-class="p-0" id="formMedicalRecord" v-model="openVoid" size="md" hide-header hide-footer>
        <div>
            <div class="modal-header">
                <h5 class="modal-title">Void Pasien</h5>
                <button type="button" @click="openVoid = false" class="close" data-dismiss="modal">×</button>
            </div>
            <div class="modal-body">
                <div class="text-center">
                <div class="modal-shout-icon mb-3 d-inline-block alpha-info text-info rounded-circle">
                    <i class="icon-check"></i>
                </div>
                <h6 class="mb-3">
                    Apakah Anda yakin akan memvoid pasien {{row.ap_fullname||"-"}}?
                </h6>
                <div>
                    <a href="javascript:;" @click="verif('N')" data-dismiss="modal" class="mr-1 btn btn-danger btn-labeled btn-labeled-left">
                    <b><i class="icon-blocked"></i></b>
                    VOID
                    </a>
                    <a href="javascript:;" @click="openVoid = false" data-dismiss="modal" class="mr-1 btn btn-outline-secondary">
                    Cancel
                    </a>
                </div>
                </div>
            </div>
        </div>
    </b-modal>

    <b-modal id="formMedicalRecord" v-model="modalwa" size="md" hide-header ok-only ok-title="Kirim Ke Whatsapp" @ok.prevent="kirimWa">
        <div class="modal-header p-0 mb-3">
            <h5 class="modal-title">Kirimkan ke Whatsapp</h5>
            <button @click="modalwa=false" type="button" class="close" data-dismiss="modal">×</button>
        </div>
        <div class="history_card" ref="printMe">
            <div class="history_card_body">
                <div class="wrap_preview_data mb-4">
                    <div class="wrap_barcode">
                    <p>Nomor Antrean</p>
                    <h2>{{row.ar_no_antrian||"-"}}</h2>
                    <hr>
                    <p>Kode Pendaftaran Online</p>
                    <h4>{{row.ar_reg_code||"-"}}</h4>
                    <img :src="row.b64image">
                    </div>
                </div>
                <div class="row g-2">
                    <div class="col-6">
                        <div class="history_info">
                            <h3>Pemeriksaan </h3>
                            <span>{{getConfigDynamic(Config.mr.statusPemeriksaanOpt,row.arres_type)||"-"}}</span>
                            <p class="text-info d-block mb-0" v-if="row.arres_type=='Poli'">{{row.mpo_name||"-"}}</p>
                            <p class="text-info d-block mb-0" v-else-if="row.arres_type=='Penunjang'">
                            {{getConfigDynamic(Config.mr.statusPenunjangOpt,row.arres_jenis_penunjang)||"-"}}</p>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="history_info">
                            <h3>Tanggal/Jam Periksa</h3>
                            <p>{{row.ar_reg_date | moment("DD MMM YYYY, HH:mm")}} WIB</p>
                        </div>
                    </div>
                </div>
                <div class="row g-2">
                    <div class="col-12">
                        <div class="history_info">
                            <h3>Diperiksa Oleh</h3>
                            <p v-if="row.arres_type == 'Poli'">{{row.bu_full_name||"-"}}</p>
                            <template v-else>
                                <p v-if="row.arres_jenis_penunjang == 'LAB'">Petugas Laboratorium</p>
                                <p v-else-if="row.arres_jenis_penunjang == 'RADIO'">Petugas Radiologi</p>
                                <p v-else-if="row.arres_jenis_penunjang == 'FISIO'">Petugas Fisioterapi</p>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>

    <validation-observer
        ref="VFormDokumen"
    >
        <b-modal v-model="openUploadDoc" title="Upload Dokumen" @ok.prevent="uploadDokumen">
            <div class="form-group">
            <label for="">Upload Dokumen Kronologi Pasien <strong class="txt_mandatory">*</strong></label>
            <Uploader isDocument v-model="row.arres_file_kecelakaan" type="docimage"/>  
            <VValidate 
                name="Kronologi Kecelakaan" 
                v-model="row.arres_file_kecelakaan" 
                :rules="{required: 1}"
            /> 
            </div>
        </b-modal>
    </validation-observer>

    
    <validation-observer
        ref="VFormKontrol"
    >
        <b-modal title="Buat SEP" size="md" v-model="modalSEPKontrol" hide-footer>
            <div class="modal-body p-0">            
                <div class="form-group">
                    <label for="">Pilih Tipe SEP</label>
                    <v-select placeholder="Pilih Jenis Tipe SEP" v-model="isType"
                        :options="Config.mr.mTypeSEP" label="text" :clearable="true"
                        :reduce="v=>v.value">
                    </v-select>
                    <VValidate  
                        name="Pengajuan SEP" 
                        v-model="isType" 
                        :rules="{required: 1}">
                    </VValidate>

                    <template v-if="isType == 'K'">
                        <label for="" class="mt-2">Tanggal Kontrol Terakhir<strong class="txt_mandatory">*</strong></label>
                        <div class="input-group mb-3">
                            <datepicker input-class="form-control transparent"
                            placeholder="Pilih Tanggal" class="my-datepicker"
                            calendar-class="my-datepicker_calendar" v-model="waktu">
                            </datepicker>
                            <div class="input-group-append calendar-group">
                            <span class="input-group-text" id="basic-addon2"><i
                                class="icon-calendar"></i></span>
                            </div>
                        </div>
                        <VValidate name="Waktu" v-model="waktu" :rules="{required : 1}" />
                    </template>

                    <template v-if="isType == 'O'">
                        <div class="row mt-2">
                            <div class="col-md-5">
                                <label for="" class="mt-2">Bulan<strong class="txt_mandatory">*</strong></label>
                                <v-select placeholder="Pilih Jenis Tipe SEP" v-model="filterRanap.bulan"
                                    :options="Config.mr.monthName" label="text" :clearable="true"
                                    :reduce="v=>v.value">
                                </v-select>
                            </div>
                            <div class="col-md-5">
                                <label for="" class="mt-2">Tahun<strong class="txt_mandatory">*</strong></label>
                                <b-form-input v-model="filterRanap.tahun" :formatter="$parent.numberOnly">
                                </b-form-input>
                            </div>
                            <div class="col-md-2">
                                <a href="javascript:;" v-b-tooltip.hover="'Cari Data'" class="btn float-right btn-primary mr-1" style="margin-top: 37px;" @click="searchHistoryData">
                                    <b><i class="icon-search4"></i></b>
                                </a>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-md-12 table-responsive">
                                <table class="table table-sm table-bordered table-hover">
                                    <thead>
                                    <tr>
                                        <th width="20">#</th>
                                        <th width="20">Aksi</th>
                                        <th>no surat kontrol</th>
                                        <th>tanggal kontrol</th>
                                        <th>no SEP</th>
                                        <th>tanggal SEP</th>
                                        <th>terbit SEP</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(v,k) in (dataRanap||[])" :key="k">
                                            <td>{{k+1}}</td>
                                            <td>
                                                <template>
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                    <input :checked="v.selected" @click="inputDataPrimerRanap(v,k)" type="radio"
                                                        name="primaryData" class="custom-control-input"
                                                        :id="`primaryData${k}`" /><label class="custom-control-label"
                                                        :for="`primaryData${k}`"></label>
                                                    </div>
                                                </template>
                                            </td>
                                            <td>{{v.noSuratKontrol||"-"}}</td>
                                            <td>{{v.tglRencanaKontrol||"-"}}</td>
                                            <td>{{v.noSepAsalKontrol||"-"}}</td>
                                            <td>{{v.tglSEP||"-"}}</td>
                                            <td>{{v.terbitSEP||"-"}}</td>
                                        </tr>
                                        <tr v-if="!(dataRanap||[]).length">
                                            <td colspan="99" class="text-center">Tidak ada data</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </template>

                </div>
            </div>
            <div class="modal-footer p-0">
                <button type="button" :disabled="isType == 'O' && !Object.keys(selectedRanap||{}).length" @click="addSEPData" class="btn btn-primary" data-dismiss="modal">Buat SEP</button>
            </div>
        </b-modal>
    </validation-observer>


    <validation-observer
        ref="VFormFinger"
    >
        <b-modal title="Pengajuan SEP Fingerprint" size="sm" v-model="openBPJSModal" @ok.prevent="addBPJSFinger">
            <div class="form-group">
                <label for="">Catatan<strong class="txt_mandatory">*</strong></label>
                <b-form-textarea v-model="catatanBPJS" :formatter="$parent.normalText"
                    name="perRiwayat" id="perRiwayat" rows="2" class="form-control"
                    placeholder="Keluhan Utama dari pasien">
                </b-form-textarea>

                <VValidate name="Catatan BPJS" v-model="catatanBPJS" :rules="{required : 1, min : 8}" />
            </div>
        </b-modal>
    </validation-observer>

    </div>
    
</template>


<script>
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
import Config from '@/libs/Config'
const moment = require('moment')
import Datepicker from 'vuejs-datepicker'


export default{
    components: {
      Datepicker
    },
    props:{
        row:Object,
        mrValidation:Object
    },
    data(){
        return {
            mPoli: [],
            mAlatBantu: [],
            activeTab: 1,
            rowPasien: {},
            openVerif: false,
            openVoid: false,
            openUploadDoc: false,
            times: null,
            dates: null,
            modalwa: false,
            modalSEPKontrol : false,
            waktu : null,
            isType : "N",
            openBPJSModal : false,
            catatanBPJS : null,
            filterRanap : {
                bulan : "01",
                tahun : "2023",
                nokartu: ""
            },
            dataRanap: [],
            selectedRanap: {},
            dataPrimerRanap: false,
            onLoadPasien: false
        }
    },
    methods: {
        inputDataPrimerRanap(v,k){
            this.selectedRanap = v
            this.dataRanap[k]['selected'] = true
        },
        searchHistoryData(){
            this.$parent.loadingOverlay = true
            let data = this.filterRanap
            data.type = 'search-history-data',
            
            Gen.apiRest(
                "/do/" + this.$parent.modulePage, {
                    data: data
                },
            "POST"
            ).then(res => {
                this.$parent.loadingOverlay = false        
                this.dataRanap = res.data
            })


        },
        deleteSEP(){
            let data = {
                type : 'delete-sep',
                regId : this.row.ar_id,
            }
            this.$parent.loadingOverlay = true
            Gen.apiRest(
                "/do/" + this.$parent.modulePage, {
                    data: data
                },
            "POST"
            ).then(res => {
                this.$parent.apiGet()
                this.$parent.loadingOverlay = false
                this.addSEPDataKontrol()
            })
        },
        addBPJSFinger(){
            this.$parent.loadingOverlay = true
            let data = {
                type : 'update-finger-print-sep',
                nobpjs : this.row.arres_no_bpjs,
                catatan : this.catatanBPJS,
                id : this.row.ar_id
            }
            Gen.apiRest(
                "/do/"+this.$parent.modulePage,
                {data: data}, 
                "POST"
            ).then(res=>{
                this.$parent.loadingOverlay = false
                let resp = res.data
                resp.statusType = 200
                this.$parent.$swal({
                    title: resp.message,
                    icon: resp.status,
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                })
                this.openBPJSModal = false
                this.$parent.apiGet()
            }).catch(err => {
                this.$parent.loadingOverlay = false
                if (err) {
                    err.statusType = err.status
                    err.status = "error"
                    err.title = err.response?.data?.title
                    err.message = err.response?.data?.message
                    err.messageError = err.message
                }
                this.$parent.doSetAlertForm(err)
                this.openBPJSModal = false
                this.$parent.apiGet()
            })
        },
        openPopupBPJS(){
            this.$parent.$swal({
                icon: 'warning',
                title: "Silakan lakukan scan Sidik Jari di aplikasi BPJS.",
            }).then(res1=>{
                if(res1.value){
                    this.$parent.$swal({
                        icon: 'warning',
                        title: "Apakah Pasien berhasil melakukan scan Sidik Jari?",
                        showCancelButton: true,
                        confirmButtonText: 'Sidik Jari Tidak Terbaca',
                        cancelButtonText: 'Berhasil',
                        cancelButtonColor: '#78D96A'
                    }).then(res2=>{
                        if(res2.isConfirmed){
                            this.openBPJSModal = true
                        }else{
                            this.$parent.loadingOverlay = true
                        
                            let data = {
                                type : 'update-finger-bpjs',
                                id : this.row.ar_id
                            }

                            Gen.apiRest(
                                "/do/"+this.$parent.modulePage,
                                {data: data}, 
                                "POST"
                            ).then(res=>{
                                this.$swal({
                                    title: `Data Finger Print Success`,
                                    icon: 'success',
                                })
                                this.$parent.apiGet()
                                this.$socket.emit('refresh_data', {to : this.uPerawat})
                            })
                        }
                    })     
                }
            })
        },
        addSEPDataKontrol(){
            this.modalSEPKontrol = true
            this.filterRanap.bulan = moment().format('MM')
            this.filterRanap.tahun = moment().format('YYYY')
            this.filterRanap.nokartu = this.row.arres_no_bpjs
            console.log(this.filterRanap)
        },
        addSEPData(){
            let data = {
                type : 'add-sep',
                regId : this.row.ar_id,
                pasienId : this.row.arres_ap_id,
                resId : this.row.arres_id,
                waktu : this.waktu,
                isType : this.isType,
                selectedRanap : this.selectedRanap
            }
            this.$parent.loadingOverlay = true
            Gen.apiRest(
                "/do/" + this.$parent.modulePage, {
                    data: data
                },
            "POST"
            ).then(res => {
                let resp = res.data
                this.modalSEPKontrol = false
                this.$parent.loadingOverlay = false
                return this.$swal({
                    title: resp.message,
                    confirmButtonText: 'Lihat SEP',
                    icon: 'success',
                }).then(()=>{
                    this.$router.push({name : 'BPJSDataSEP', params:{pageSlug : res.data.data.id}}).catch(()=>{})
                })
            }).catch(err=>{ 
                this.$parent.loadingOverlay = false 
                if(err){
                    err.statusType = err.status
                    err.status = "error"
                    err.message = err.response.data.message
                    err.title = err.response.data.title
                    err.messageError = err.message
                }
                this.$parent.doSetAlertForm(err)
            })
        },
        printPage(){
            this.times = moment().format('HH:mm:ss')
            this.dates = moment().format('dddd, Do MMMM YYYY')

            this.$parent.loadingOverlay = true

            let data = {
                poli_id : this.row.arres_poli
            }
            Gen.apiRest(
                "/get/"+"RoPasienNew"+'/'+this.row.arres_ap_id, 
                {
                    params: data
                }
            ).then(res=>{
                this.rowPasien = res.data.row
                this.$parent.loadingOverlay = false

                let newWindow = window.open('', '', 'left=0,top=0,width=302,height=350,toolbar=0,scrollbars=0,status=0,addressbar=0'),
                document = newWindow.document.open(),
                pageContent =
                    '<!DOCTYPE html>' +
                    '<html>' +
                    '<head>' +
                    '<meta charset="utf-8" />' +
                    '<title>Inventory</title>' +
                    '<style type="text/css">body {-webkit-print-color-adjust: exact; font-family: Arial; }</style>' +
                    '</head>' +
                    '<body>' +
                    `<div style="position: relative;max-width: 400px;">
                        <div
                        style="height:100%;position: relative;font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;color: #333;line-height: 1.5;font-size: 13px;">
                        <div>
                            <table border="0" style="border-collapse: collapse;width: 100%;">
                            <tr>
                                <td style="padding:0;">
                                <table style="border-collapse: collapse;width: 100%;">
                                    <tr>
                                        <td style="padding:8px 12px;">
                                            <div
                                            style="float: left;width: 15%;vertical-align: middle;box-sizing: border-box;padding-right: 8px;">
                                            <img src="${this.$parent.assetLocal('global_assets/images/logo-rsh-mark.png')}" style="width: 100%;" alt="">
                                            </div>
                                            <div
                                            style="text-transform: uppercase;float: left;width: 75%;vertical-align: middle;box-sizing: border-box;padding-left: 8px;text-align: center;">
                                            <h1 style="margin:0;font-size: 14px;line-height: 20px;margin-bottom: 2px;">Rumah Sakit Harapan
                                                Magelang</h1>
                                            </div>
                                            <div style="display: table-cell; clear: both;"></div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="padding:2px 12px;">
                                            <p style="margin:0;font-size: 12px;text-align:center;">Jl. P. Senopati No. 11 Magelang 56123<br />Telp. (0293) 364033
                                                s/d 364035 <br /> Fax. (0293) 364037</p>
                                            <div style="display: table-cell; clear: both;"></div>
                                         </td>
                                    </tr>
                                </table>
                                </td>
                            </tr>
                            <tr>
                                <td style="padding:20px;">
                                <table style="border-collapse: collapse;width: 100%;">
                                    <tbody>
                                    <tr>
                                        <td colspan="2">
                                        <p style="margin:  0; text-align:center; font-size:16px;">BUKTI RESERVASI ANTRIAN: </p>
                                        <h3 style="font-weight: bold; text-transform: uppercase;letter-spacing: 6px;text-align: center;font-size: 60px;
                                            margin: 0px 0 10px 0; line-height:1;">
                                            ${this.row.ar_no_antrian||'-'}</h3>
                                        <p style="margin: 8px 0; text-align:center; font-size:14px;">Nomor antrian ini berlaku sebagai
                                            nomor antrian perawat, antrian periksa dokter, dan antrian  pengambilan obat.
                                        </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        <p style="margin:  10px 0 0 0; color: #000; font-size:12px;">JAMINAN </p>
                                        </td>
                                        <td>
                                        <p style="margin:  10px 0 0 0;  color: #000; font-size:12px;">:
                                        ${this.row.mcp_name||'-'} </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        <p style="margin:  0px 0 0 0; color: #000; font-size:12px;">TANGGAL/JAM </p>
                                        </td>
                                        <td>
                                        <p style="margin:  0; color: #000; font-size:12px;">: ${moment().format('DD MMM YYYY, HH:mm')}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        <p style="margin:  0 0 10px 0; color: #000; font-size:12px;">NOMOR RM </p>
                                        </td>
                                        <td>
                                        <p style="margin:  0 0 10px 0; color: #000; font-size:12px;"> :${this.rowPasien.ap_code||"-"}</p>
                                        </td>
                                    </tr>
                                    <tr style="border-top:2px solid #333;">
                                        <td>
                                        <p style="margin:  10px 0 0 0; color: #000; font-size:12px;">NAMA </p>
                                        </td>
                                        <td>
                                        <p style="margin:  10px 0 0 0; color: #000; font-size:12px;"> : ${this.rowPasien.ap_fullname||"-"}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        <p style="margin:  0; color: #000; font-size:12px;">ALAMAT </p>
                                        </td>
                                        <td>
                                        <p style="margin:  0; color: #000; font-size:12px;"> : ${this.rowPasien.ap_address||"-"}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        <p style="margin:  0 0 10px 0;  color: #000; font-size:12px;">KOTA </p>
                                        </td>
                                        <td>
                                        <p style="margin:  0 0 10px 0;  color: #000; font-size:12px;"> : ${this.rowPasien.ap_kota||"-"}</p>
                                        </td>
                                    </tr>
                                    <tr style="border-top:2px solid #333;">
                                        <td>
                                        <p style="margin:  10px 0 0 0; color: #000; font-size:12px;">TANGGAL LAHIR </p>
                                        </td>
                                        <td>
                                        <p style="margin:  10px 0 0 0; color: #000; font-size:12px;">:
                                        ${moment(this.rowPasien.ap_dob_true).format('DD MMM YYYY')} </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        <p style="margin:  0; color: #000; font-size:12px;">LAYANAN  </p>
                                        </td>
                                        <td>
                                        <p style="margin:  0; color: #000; font-size:12px;"> : ${this.row.mpo_name||"-"}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        <p style="margin:  0px 0 10px 0;  color: #000; font-size:12px;">DOKTER </p>
                                        </td>
                                        <td>
                                        <p style="margin:  0px 0 10px 0;  color: #000; font-size:12px;"> : ${this.row.bu_full_name||"-"} </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        <p style="margin:  0px 0 10px 0;  color: #000; font-size:12px;">LOKET PERAWAT </p>
                                        </td>
                                        <td>
                                        <p style="margin:  0px 0 10px 0;  color: #000; font-size:12px;"> : <b>${this.row.loket_perawat||"-"} </b></p>
                                        </td>
                                    </tr>

                                    <tr style="${this.row.berlaku_rujukan?'': 'display:none;'}">
                                        <td>
                                        <p style="margin:  0px 0 10px 0;  color: #000; font-size:12px;">Tanggal Berlaku Rujukan </p>
                                        </td>
                                        <td>
                                        <p style="margin:  0px 0 10px 0;  color: #000; font-size:12px;"> : <b>${this.row.berlaku_rujukan} </b></p>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                </td>
                            </tr>
                            </table>
                        </div>
                        </div>
                    </div>` +
                    '</body></html>'
                document.write(pageContent)
                document.close()
                newWindow.moveTo(0, 0)
                newWindow.resizeTo(screen.width, screen.height)
                setTimeout(function() {
                    newWindow.print()
                    newWindow.close()
                }, 250)
            })

        },
        cetakKartu(){
            this.printPage()
            let data ={
                type: 'update-task-2',
                id: this.row.ar_id
            }
            Gen.apiRest(
                "/do/"+this.$parent.modulePage,
                {data: data}, 
                "POST"
            ).then(res=>{
                console.log(res)
            })
        },
        kirimWa(){
            let el = this.$refs.printMe
            let options = {
                type: 'dataURL'
            }
            let self = this
            self.$parent.loadingOverlay = true
            self.modalwa = false
            this.$html2canvas(el, options).then(res=>{
                let data = {
                    id : self.row.arres_ap_id,
                    type : 'share-wa',
                    base_64 : res
                }
                Gen.apiRest(
                    "/do/"+ self.$parent.modulePage,
                    {data: data}, 
                    "POST"
                ).then(res=>{
                    self.$parent.loadingOverlay = false
                    self.$swal({
                        title: `Data Berhasil Dikirim`,
                        icon: 'success',
                    })
                })
            })
            
        },
        uploadDokumen(){
            this.$refs['VFormDokumen'].validate().then(success=>{
                if(success){
                    let data ={
                        type: 'update-dok',
                        arres_id: this.row.arres_id,
                        arres_file_kecelakaan: this.row.arres_file_kecelakaan
                    }
                    Gen.apiRest(
                        "/do/"+this.$parent.modulePage,
                        {data: data}, 
                        "POST"
                    ).then(res=>{
                        this.openUploadDoc = false
                        this.$swal({
                            title: `Data Berhasil Diupload`,
                            icon: 'success',
                        })
                    })

                }
            })
        },
        back(){
            this.$router.back()
        },
        verif(isVerif){ 
            this.$swal({
                html: 'Menyimpan perubahan...',
                allowOutsideClick: false,
                allowEscapeKey: false,
                didOpen: () => {
                    this.$swal.showLoading()
                }
            })

            let data ={
                isverif : isVerif,
                type: 'verif-data-pasien',
                ar_id: this.row.ar_id
            }
            Gen.apiRest(
                "/do/"+this.$parent.modulePage,
                {data: data}, 
                "POST"
            ).then(res=>{
                this.openVerif = false
                this.openVoid = false

                if(isVerif == 'Y'){
                    this.$swal({
                        title: `Data Berhasil Diverifikasi`,
                        icon: 'success',
                        didOpen: () => {
                            this.$swal.hideLoading()
                        }
                    })
                }else if(isVerif == 'I'){
                    this.$swal({
                        title: `Data Invalid`,
                        text: 'Silakan ubah cara bayar Pasien menjadi Umum/Pribadi atau Asuransi.',
                        icon: 'success',
                        didOpen: () => {
                            this.$swal.hideLoading()
                        }
                    })
                }else{
                    this.$swal({
                        title: `Data Berhasil Divoid`,
                        icon: 'success',
                        didOpen: () => {
                            this.$swal.hideLoading()
                        }
                    })
                }
                
                this.$parent.apiGet()
            })
        },
        apiGetMaster(){
            let data = {
                poli_id : this.row.arres_poli,
                isViewDetail: 'Y'
            }
            Gen.apiRest(
                "/get/"+this.$parent.modulePage+'/master', 
                {
                    params: data
                }
            ).then(res=>{
                _.forEach(res.data, (v,k)=>{
                    this.$set(this, k, v)
                })
            })
        },
        getConfigDynamic(master, value, val = 'text') {
            let text = ''
            if (value) {
                let index = (master||[]).findIndex(x => x.value == value)
                if (index !== -1) {
                text = master[index][val]
                }
            }
            return text
        },
        changeTab(e){
            this.activeTab = e
        },
        apiGetPasien(){
            if (this.onLoadPasien === false && this.row.arres_ap_id) {
                this.onLoadPasien = true

                let data = {
                    poli_id : this.row.arres_poli
                }
                Gen.apiRest(
                    "/get/"+"RoPasienNew"+'/'+this.row.arres_ap_id, 
                    {
                        params: data
                    }
                ).then(res=>{
                    this.rowPasien = res.data.row
                })
            }
        },
        baseUrlFinger(){
          return process.env.VUE_APP_FINGER_PRINT_DISPLAY
        },
        openQR(){
          this.$parent.loadingOverlay = true
          let url = ''
          if(this.row.jaminan == 3){
            // url = this.baseUrlFinger()+'?type=bpjs&noRM='+this.rowPasien.ap_code
            this.openBPJSModal = true

            this.$parent.$swal({
                icon: 'warning',
                title: "Silakan lakukan scan Sidik Jari di aplikasi BPJS.",
            })
          }else{
            url = this.baseUrlFinger()+'?noRM='+this.rowPasien.ap_code
            setTimeout(()=>{
                this.$parent.loadingOverlay = false
                window.open(url, '_blank')
            },1000)
          }
        },
        bypassFinger(v){
            this.$parent.loadingOverlay = true
            let data = {
                type : 'bypass-finger',
                id : v.ar_id
            }
            Gen.apiRest(
                "/do/"+this.$parent.modulePage,
                {data: data}, 
                "POST"
            ).then(res=>{
                if(res.data.status == 'error'){
                    this.$parent.apiGet()                
                    return this.$swal({
                        title: `Silakan Coba Kembali`,
                        icon: 'error',
                    })
                }
                this.$swal({
                    title: `Data Berhasil DI Bypass`,
                    icon: 'success',
                })
                this.$parent.apiGet()
                this.$socket.emit('refresh_data', {to : this.uPerawat})
            })
        },
    },
    computed: {    
        Config(){ return Config },
        
        ktpAddr(){
        if(this.rowPasien.ap_address && this.rowPasien.ap_rt && this.rowPasien.ap_rw && this.rowPasien.ap_kelurahan && this.rowPasien.ap_kecamatan && this.rowPasien.ap_kota){
            return (this.rowPasien.ap_address||"-") + ", RT."+(this.rowPasien.ap_rt||"-")+"/"+(this.rowPasien.ap_rw||"-")+", DESA "+
            (this.rowPasien.ap_kelurahan||"-")+", KEC."+(this.rowPasien.ap_kecamatan||"-")+ ", KAB/KOTA"+(this.rowPasien.ap_kota||"-")
        }else{
            return "-"
        }
        },

        domisiliAddr(){
        if(this.rowPasien.ap_dom_address && this.rowPasien.ap_dom_rt && this.rowPasien.ap_dom_rw && this.rowPasien.ap_dom_kelurahan && this.rowPasien.ap_dom_kecamatan && this.rowPasien.ap_dom_kota){
            return (this.rowPasien.ap_dom_address||"-") + ", RT."+(this.rowPasien.ap_dom_rt||"-")+"/"+(this.rowPasien.ap_dom_rw||"-")+", DESA "+
            (this.rowPasien.ap_dom_kelurahan||"-")+", KEC."+(this.rowPasien.ap_dom_kecamatan||"-")+ ", KAB/KOTA"+(this.rowPasien.ap_dom_kota||"-")
        }else{
            return "-"
        }
        },
        
        pjAddr(){
        if(this.rowPasien.arpj_address && this.rowPasien.arpj_rt && this.rowPasien.arpj_rw && this.rowPasien.arpj_kelurahan && this.rowPasien.arpj_kecamatan && this.rowPasien.arpj_kota){
            return (this.rowPasien.arpj_address||"-") + ", RT."+(this.rowPasien.arpj_rt||"-")+"/"+(this.rowPasien.arpj_rw||"-")+", DESA "+
            (this.rowPasien.arpj_kelurahan||"-")+", KEC."+(this.rowPasien.arpj_kecamatan||"-")+ ", KAB/KOTA"+(this.rowPasien.ap_dom_kota||"-")
        }else{
            return "-"
        }
        },
    },
    mounted() {
        setTimeout(()=>{
            this.apiGetMaster()
            this.apiGetPasien()
        },2500)
    },
    watch:{
        'row.arres_ap_id'(v, old){
            if (old) {
                this.onLoadPasien = false
            }
            this.apiGetPasien()
        }
    },
}

</script>