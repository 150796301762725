<template>
    <div class="content-wrapper">
        <bo-page-title>
            <template v-if="isList" slot="additionalCta">
                <b-button @click="addNewReservasi" class="btn bg-blue-400 btn-labeled btn-labeled-left ml-1">
                    <b><i class="icon-plus2"></i></b> Buat Reservasi Baru
                </b-button>
            </template>
        </bo-page-title>

        <div v-if="isList" class="content pt-0">
            <div class="card">
                <div class="card-header bg-white">
                    <div class="row align-items-center">
                        <div class="col-md-5">
                            <div class="form-row align-items-center">
                                <div class="col-md-4">
                                    <h5 class="card-title font-weight-semibold">Daftar Pasien</h5>
                                </div>
                                <div class="col-md-8">
                                    <div class="form-group mb-0">
                                            <div class="input-group">
                                                <date-range-picker
                                                    ref="picker"
                                                    :locale-data="datePickerConfig.locale"
                                                    :autoApply="datePickerConfig.autoApply"
                                                    v-model="dateRange"
                                                    :opens="'right'"
                                                    :ranges="datePickerConfig.ranges"
                                                    @update="updateValues"
                                                >
                                                    <template v-slot:input="picker">
                                                        {{ picker.startDate | date }} - {{ picker.endDate | date }}
                                                    </template>
                                                </date-range-picker>
                                                <div class="input-group-append calendar-group">
                                                    <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-auto col-lg-7">
                            <div class="form-row justify-content-end">
                                <div class="col-md-5">
                                    <div class="lbl_status_pasien bg_red">
                                        <h3>
                                            <i class="icon-users2"></i>
                                            {{pasienBaru}} PASIEN BARU
                                        </h3>
                                    </div>
                                </div>
                                <div class="col-md-5">
                                    <div class="lbl_status_pasien bg_green">
                                        <h3>
                                            <i class="icon-user-check"></i>
                                            {{pasienLama}} PASIEN LAMA
                                        </h3>
                                    </div>
                                </div>
                                <!--
                                <div class="col-md-auto">
                                    <a href="#" data-toggle="modal" data-target="#FunnelData"
                                        class="lbl_status_pasien bg_blue">
                                        <h3><i class="icon-menu"></i></h3>
                                    </a>
                                </div>
                                -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-header">
                    <div class="row gx-0">
                        <div class="col-lg-12">
                            <div class="row gx-1 gy-1">
                                <div class="col-md-auto">
                                    <div class="form-group mb-0">
                                        <button @click="doRefreshData" data-toggle="modal" data-target="#FilterField" data-popup="tooltip" class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover ><i class="icon-spinner11"></i></button>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <b-form-group class="mb-0">
                                        <v-select placeholder="Pilih Pemeriksaan" @input="doFill" v-model="filter.status_periksa"  :options="Config.mr.statusPemeriksaan" label="text" :reduce="v=>v.value"></v-select>
                                    </b-form-group>
                                </div>
                                <div class="col-md-3">
                                    <b-form-group class="mb-0">
                                        <v-select placeholder=" -- Pilih Jaminan -- " @input="doFill" v-model="filter.status_jaminan"  :options="Config.mr.statusJaminan" label="text" :reduce="v=>v.value"></v-select>
                                    </b-form-group>
                                </div>
                                <div class="col-md-3">
                                    <b-form-group class="mb-0">
                                        <v-select placeholder=" -- Pilih Poli -- " @input="doFill" v-model="filter.poli"  :options="mPoli" label="text" :reduce="v=>v.value"></v-select>
                                    </b-form-group>
                                </div>
                                <div class="col-md-3">
                                    <input type="text" placeholder="Cari Tanggal Lahir" @input="fillDOB($event)" class="form-control" v-mask="'##/##/####'" v-model="filter.dob_filter">
                                </div>
                                
                                <div class="col-md-4">
                                    <b-form-group class="mb-0">
                                        <v-select placeholder=" -- Urutkan Berdasarkan Waktu Checkin -- " @input="doFill" v-model="filter.waktu_checkin"  :options="mUrutkanWaktu" label="text" :reduce="v=>v.value" :clearable="true"></v-select>
                                    </b-form-group>
                                </div>
                                
                                <div class="col-md-2">
                                    <b-form-group class="mb-0">
                                        <v-select placeholder=" -- Pilih Status -- " @input="doFill" v-model="filter.status_reservasi"  :options="mStatus" label="text" :reduce="v=>v.value"></v-select>
                                    </b-form-group>
                                </div>

                                <div class="col-lg-4">
                                    <div class="form-group form-group-feedback form-group-feedback-right mb-0">
                                        <input class="form-control"
                                            v-model="filter.search"
                                            v-on:keyup.enter="doSearch"
                                            placeholder="Ketik Nama/No. RM Pasien & hit Enter!"
                                        />
                                        <div @click="doSearch" class="form-control-feedback">
                                            <i class="icon-search4 text-indigo"></i>
                                        </div>
                                    </div>  
                                </div>
                                
                                <div class="col-md-3">
                                    <b-form-group class="mb-0">
                                        <v-select placeholder=" -- Pilih Dokter -- " @input="doFill" v-model="filter.dokter"  :options="mDokter" label="text" :reduce="v=>v.value"></v-select>
                                    </b-form-group>
                                </div>
                                
                                <div class="col-md-3">
                                    <b-form-group class="mb-0">
                                        <v-select placeholder=" -- Pilih Cara Daftar -- " @input="doFill" v-model="filter.cara_daftar"  :options="Config.mr.caraDaftar" label="text" :reduce="v=>v.value"></v-select>
                                    </b-form-group>
                                </div>
                                
                                <div class="col-md-3">
                                    <b-form-group class="mb-0">
                                        <v-select placeholder=" -- Pilih Status Checkin -- " @input="doFill" v-model="filter.status_checkin"  :options="Config.mr.statusCheckin" label="text" :reduce="v=>v.value"></v-select>
                                    </b-form-group>
                                </div>

                                <div class="col-md-2">
                                    <b-button
                                        class="btn-block"
                                        variant="outline-success"
                                        id="resetBtn"
                                        @click="doResetData()"
                                    >Reset</b-button>
                                </div>
                                
                                <div class="col-md-auto">
                                    <div class="form-group mb-0">
                                        <button styledata-toggle="modal" data-target="#FilterField" data-popup="tooltip" class="btn btn-light" style="opacity: 0;"><i class="icon-spinner11"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ul class="nav nav-justified nav-tabs nav-tabs-highlight mb-0">
                    <li class="nav-item"><a href="javascript:;" @click="changeTab('N')" :class="tabDraft == 'N' ? 'nav-link active' : 'nav-link'">Data Aktif</a></li>
                    <li class="nav-item"><a href="javascript:;" @click="changeTab('Y')" :class="tabDraft == 'Y' ? 'nav-link active' : 'nav-link'" class="nav-link"> Data Draft</a></li>
                </ul>
                <div class="tab-content">
                    <div :class="'tab-pane fade show active'">
                        <div class="table-responsive">
                            <table
                                class="table table-bordered table-striped table-hover table-sm patient-table text-uppercase table-sm">
                                <thead>
                                    <tr>
                                        <th>Antrean</th>
                                        <th>Nama / No.KTP</th>
                                        <th>No RM</th>
                                        <th>No Hp</th>
                                        <th>Pemeriksaan</th>
                                        <th>Nama Poli / Penunjang</th>
                                        <th>Nama Dokter</th>
                                        <th>Waktu Periksa</th>
                                        <th>Waktu Checkin</th>
                                        <th>Cara Bayar / Asal Rujukan Pasien</th>
                                        <th>Status</th>
                                        <th>Didaftarkan di</th>
                                        <th>Didaftarkan Oleh</th>
                                        <th width="120">Aksi</th>
                                    </tr>
                                </thead>
                                <tbody v-if="(dataList||[]).length">
                                    <tr v-for="(v,k) in (dataList||[])" :key="k">
                                        <td>
                                            <div class="text-success-700 font-weight-semibold">{{v.ar_reg_code||"-"}}</div>
                                            <small class=" font-weight-semibold" style="font-size: 18px;">{{v.ar_no_antrian||"-"}}</small>
                                        </td>
                                        <td>
                                            <div>{{v.ap_fullname||"-"}}</div>
                                            <div class="text-info">{{v.ap_nik||"-"}}</div>
                                        </td>
                                        <td> 
                                            <template v-if="v.ap_is_old_pasien">
                                                <span class="text-info" v-if="v.ap_is_old_pasien !== 'Y'">PASIEN BARU</span>
                                                <span class="text-danger" v-else>PASIEN LAMA</span>
                                            </template>
                                            <template v-else>
                                                <span> - </span>
                                            </template>                              
                                            <div class="font-weight-semibold">{{v.ap_code||"-"}}</div>
                                        </td>
                                        <td>
                                            <span v-if="v.ap_phone_number">
                                                +62{{v.ap_phone_number||"-"}}
                                            </span>
                                            <span v-else> - </span>
                                        </td>
                                        <td>
                                            <div class="font-weight-semibold">{{getConfigDynamic(Config.mr.statusPemeriksaan,v.arres_type)||"-"}}</div>
                                        </td>
                                        
                                        <td>
                                            <span v-if="v.arres_type == 'Poli'">
                                                {{v.mpo_name||"-"}}
                                            </span>
                                            <template v-else>
                                                <span v-if="v.arres_jenis_penunjang == 'FISIO'">
                                                    FISIOTERAPI
                                                </span>
                                                <span v-else-if="v.arres_jenis_penunjang == 'LAB'">
                                                    LABORATORIUM
                                                </span>
                                                <span v-else-if="v.arres_jenis_penunjang == 'RADIO'">
                                                    RADIOLOGI
                                                </span>
                                                <span v-else-if="v.arres_jenis_penunjang == 'DIETISIEN'">
                                                    DIETISIEN
                                                </span>
                                            </template>

                                            <span v-if="v.ar_penunjang_data" :class="`badge bg-success mb-1`">Terdapat Registrasi Penunjang</span>
                                        </td>
                                        
                                        <td>
                                            <span>{{v.bu_full_name||"-"}}</span>
                                        </td>
                                        
                                        <td>
                                            <span v-if="v.ar_reg_date">{{v.ar_reg_date | moment("DD MMM YYYY, HH:mm")}} WIB</span>
                                            <span v-else> - </span>
                                        </td>
                                        
                                        <td>
                                            <span v-if="v.ar_checkin_at">{{v.ar_checkin_at | moment("DD MMM YYYY, HH:mm")}} WIB</span>
                                            <span v-else> - </span>
                                        </td>

                                        <td>
                                            <div class="font-weight-semibold">{{getConfigDynamic(Config.mr.statusJaminan,v.arres_jaminan)||"-"}}</div>
                                            {{getConfigDynamic(mAsalRujukan,v.arres_asal_rujukan)}}
                                        </td>
                                        <td>
                                            <template v-if="v.arres_is_draft !== 'Y'">
                                                
                                                <template v-if="v.ar_is_checkin == 'Y' && v.ar_is_verif_admisi == 'Y' && v.ar_is_finger == 'Y'">
                                                    
                                                    
                                                    <template v-if="(v.ar_status <= sFisio) || (v.ar_status == 99 || v.ar_status == 98)">
                                                        <span :class="`badge ${v.ars_color} mb-1`">{{uppercaseWord(v.ars_name)||'-'}}</span>
                                                    </template>
                                                    
                                                    <template v-else>
                                                        <template v-if="v.ar_status == sPPA">
                                                            <span v-if="v.ar_is_ppa_fisio == 'Y' && v.ar_is_done_ppa_fisio !== 'Y'"
                                                                :class="'badge bg-blue-600 text-white mb-1'">Fisioterapi</span>
                                                            <span v-if="v.ar_is_ppa_radiologi == 'Y' && v.ar_is_done_ppa_radiologi !== 'Y'"
                                                                :class="'badge bg-blue-600 text-white mb-1'">Radiologi</span>
                                                            <span v-if="v.ar_is_ppa_lab == 'Y' && v.ar_is_done_ppa_lab !== 'Y'"
                                                                :class="'badge bg-blue-600 text-white mb-1'">Laboratorium</span>
                                                            <span v-if="v.ar_is_ppa_dietisien == 'Y' && v.ar_is_done_ppa_dietisien !== 'Y'"
                                                                :class="'badge bg-info text-white mb-1'">Dietisien</span>
                                                        </template>

                                                        <span v-else :class="`badge ${v.ars_color} mb-1`">{{uppercaseWord(v.ars_name)||'-'}}</span>
                                                    </template>
                                                   

                                                    <span v-if="v.ar_is_belum_datang == 'Y'"
                                                        :class="`badge badge-info mt-1`">{{uppercaseWord('Belum Datang')||'-'}}</span>
                                                </template>

                                                <span class="badge badge-info mb-1" v-if="v.ar_is_checkin !== 'Y' && v.ar_is_finger !== 'Y'"> Belum Checkin </span>
                                                
                                                <span class="badge badge-info mb-1" v-if="v.ar_is_checkin == 'Y' && v.ar_is_finger !== 'Y'"> Belum Checkin </span>
                                                
                                                <span class="badge badge-info mb-1" v-if="v.ar_is_checkin !== 'Y' && v.ar_is_finger == 'Y'"> Belum Checkin </span>


                                                <span class="badge badge-warning mb-1" v-if="!v.absd_no_sep && v.arres_jaminan == 3 && v.ar_is_reg_penunjang != 'Y'"> Buatkan SEP </span>

                                     
                                                

                                            </template>

                                            <span class="badge bg-primary mb-1 mt-1" v-if="v.ar_is_fast_track == 'Y'">FAST TRACK</span>

                                            <span class="badge bg-warning mb-1" v-if="v.arres_is_draft == 'Y'">Draft</span>

                                            <span class="badge bg-warning" v-if="v.arres_is_draft !== 'Y' && v.ar_is_verif_admisi !== 'Y' && v.ar_is_unverif_admisi !== 'Y'">Verifikasi Asuransi</span>
                                            
                                            <span class="badge bg-warning" v-if="v.arres_is_draft !== 'Y' && v.ar_is_verif_admisi !== 'Y' && v.ar_is_unverif_admisi == 'Y'">Asuransi Tidak Valid</span>

                                            
                                            <span class="badge bg-danger mb-1" v-if="v.arres_status == 99">Dibatalkan</span>

                                            <span class="badge bg-danger mb-1" v-if="v.arres_status == 4">Kadaluwarsa</span>

                                            <span class="badge bg-danger mb-1" v-if="v.ar_is_void == 'Y'">VOID</span>                                            
                                        </td>
                                        <td>
                                            <span v-if="v.ar_created_from == 'E-REG'">Pendaftaran Online</span>
                                            <span v-else-if="v.ar_created_from == 'BO-REG'">Pendaftaran Offline</span>
                                            <span v-else-if="v.ar_created_from == 'JKN'">Mobile JKN</span>
                                            <span v-else>ICS</span>
                                        </td>
                                        <td>
                                            {{v.nama_admisi || "-"}}
                                        </td>
                                        <td>
                                            <div class="d-flex">
                                            <router-link class="btn btn-icon rounded-round btn-sm mr-1 alpha-info border-info"
                                            v-if="moduleRole('lihat_detail') && v.arres_is_draft !== 'Y'"
                                            :to="{name: $route.name, params: {pageSlug: v.arres_id}, query: {isDetail: true}}"
                                            data-toggle="tooltip" v-b-tooltip.hover data-placement="top" v.b- title="View"><i class="icon-eye"></i></router-link>

                                            <router-link class="btn btn-icon rounded-round btn-sm mr-1 alpha-info border-info"
                                            :to="{name: $route.name, params: {pageSlug: v.arres_id}}"
                                            data-toggle="tooltip" v-b-tooltip.hover data-placement="top" title="Edit Data"><i class="icon-pencil5"></i></router-link> 
                                            
                                            <a href="javascript:;" v-if="v.arres_is_draft == 'Y'" class="btn btn-icon rounded-round btn-sm mr-1 alpha-info border-info"
                                            @click="doDelete(k, v)"
                                            :to="{name: $route.name, params: {pageSlug: v.arres_id}}"
                                            data-toggle="tooltip" v-b-tooltip.hover data-placement="top" title="Hapus Data"><i class="icon-bin"></i></a> 

                                            <a href="javascript:;" v-if="v.ar_is_fast_track !== 'Y' && v.arres_is_draft !== 'Y'" data-toggle="modal" @click="doFastTrack(v)" data-target="#Fasttrack" data-popup="tooltip" v-b-tooltip.hover title="Pasien Fast Track" class="btn btn-icon rounded-round btn-sm alpha-danger border-danger" data-original-title="Pasien Fast Track"><i class="icon-list-numbered"></i></a>

                                            
                                            <a href="javascript:;" v-if="v.arres_jaminan == 3" data-toggle="modal" @click="doOpenKontrol(v)" data-target="#KontrolData" data-popup="tooltip" v-b-tooltip.hover title="Data Rencana Kontrol" class="btn btn-icon rounded-round btn-sm alpha-success border-success ml-1" data-original-title="Data Rencana Kontrol"><i class="icon-calendar"></i></a>

                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-if="data.data.length == 0">
                                    <tr>
                                        <td colspan="99">
                                        <div class="text-center">
                                            <h4 align="center"><span v-if="filter.status || filter.search || filter.startDate || filter.endDate">Hasil pencarian tidak ditemukan</span>
                                            <span v-else>Belum Ada Data {{ pageTitle }} yang terdaftar</span></h4>
                                        </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-if="!data.data">   
                                    <tr>
                                        <td colspan="99"><div class="skeletal-comp"></div></td>
                                    </tr>
                                    <tr>
                                        <td colspan="99"><div class="skeletal-comp"></div></td>
                                    </tr>
                                    <tr>
                                        <td colspan="99"><div class="skeletal-comp"></div></td>
                                    </tr>
                                </tbody>
                            </table>
                            
                            <div :class="(data.data||[]).length && !loadingTable && data.data ? 'table-scroll-actions' : 'table-scroll-actions d-none'">
                                <a href="javascript:;" data-scroll="left" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
                                <i class="icon-chevron-left"></i>
                                </a>
                                <a href="javascript:;" data-scroll="right" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
                                <i class="icon-chevron-right"></i>
                                </a>
                            </div>

                        </div>
                        <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>data.per_page">     <b-pagination
                            class="mb-0"
                            v-model="pageNo"
                            :per-page="data.per_page"
                            :total-rows="data.total"
                        />
                        </b-card-footer>
                    </div>
                </div>

            </div>
        </div>
            
        <template v-else>
            <FormDetail v-if="isDetail" :row.sync="row" v-bind="passToSub" />
            <Form v-else :row.sync="row" v-bind="passToSub" />
        </template>

		<validation-observer ref="VFormFastTrack">
        <b-modal v-model="openModalFastTrack" :title="'Indikasi Fast Track'" size="sm" @ok.prevent="submitFastTrack">
            <div class="row">
                <div class="form-group col-md-12">
                    <label>Indikasi Fast Track <small class="txt_mandatory">*</small></label>
                    <b-form-radio-group
                        :options="Config.mr.mFastTrack"
                        v-model="rowEdit.arres_is_alat_bantu"
                    />
                    <VValidate 
                        name="Indikasi Fast Track" 
                        v-model="rowEdit.arres_is_alat_bantu" 
                        :rules="{required: 1}"
                    />
                </div>

                <template v-if="rowEdit.arres_is_alat_bantu">
                    <div class="col-12" id="Formalatbantu" v-if="rowEdit.arres_is_alat_bantu == 'Y'">
                        <div class="form-group">
                        <label>Alat Bantu<small class="txt_mandatory">*</small></label>
                        <v-select placeholder="Pilih Alat Bantu" v-model="rowEdit.arres_alat_bantu"  :options="mAlatBantu" label="text" :reduce="v=>v.value"></v-select>
                        </div>
                        <VValidate 
                            name="Alat Bantu" 
                            v-model="rowEdit.arres_alat_bantu" 
                            :rules="{required: 1}"
                        />
                    </div>
                    <div class="col-12" id="FormFtLainnya" v-else>
                        <div class="form-group">
                        <textarea v-model="rowEdit.ar_fast_track_notes" name="ftNote" id="ftNote" rows="4" class="form-control"
                            placeholder="e.g. kondisi pasien sudah urgent"></textarea>
                        </div>
                        <VValidate 
                            name="Catatan Fast Track" 
                            v-model="rowEdit.ar_fast_track_notes" 
                            :rules="{required: 1}"
                        />
                    </div>
                </template>
            </div>
        </b-modal>
        </validation-observer>

        <b-modal v-model="openKontrol" :title="'Data Riwayat Kontrol'" size="md" ok-title="Tutup" ok-only>
            <div class="row">
                <div class="col-md-12">
                    <table
                        class="table table-bordered table-striped table-hover table-sm patient-table text-uppercase table-sm">
                        <thead>
                            <tr>
                                <th>No Kontrol</th>
                                <th>Tanggal Kontrol</th>
                                <th>Poli</th>
                                <th>Dokter</th>
                                <th>Cetak</th>
                            </tr>
                            <tr v-if="Object.keys(dataRencanaKontrol||{}).length">
                                <td>{{dataRencanaKontrol.arrk_no_sk||"-"}}</td>
                                <td>{{dataRencanaKontrol.arrk_tanggal_kontrol | moment("DD MMMM YYYY") }}</td>
                                <td>{{dataRencanaKontrol.poli||"-"}}</td>
                                <td>{{dataRencanaKontrol.dokter||"-"}}</td>
                                <td>
                                    <a v-if="dataRencanaKontrol.arrk_is_created_sk == 'Y'" href="javascript:;" class="btn btn-icon rounded-round btn-sm mr-1 alpha-info border-info mt-1"
                                    @click="doCetakSK(dataRencanaKontrol)"
                                    data-toggle="tooltip" data-placement="top" v-b-tooltip.hover title="Cetak"><i
                                        class="icon-printer"></i></a>
                                    <span v-else> - </span>
                                </td>


                            </tr>
                            <tr v-else>
                                <td colspan="5" class="text-center">Tidak terdapat jadwal kontrol</td>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
        </b-modal>
    </div>
</template>


<script>

    import GlobalVue from '@/libs/Global.vue'
    import DateRangePicker from 'vue2-daterange-picker'
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
    const moment = require('moment')

    const _ = global._
    import $ from 'jquery'
    import Gen from '@/libs/Gen.js'
    import Form from './Form.vue'
    import FormDetail from './FormDetail.vue'

    export default {
        extends: GlobalVue,
        computed:{
            isDetail(){
                return this.$route.query.isDetail
            },
            passToSub(){
                return {
                    Config:this.Config,
                    mrValidation: this.mrValidation,
                    rowPasien: this.rowPasien,
                    listRujukan : this.listRujukan
                }
            },
        },
        data(){
            return {
                idKey:'arres_id',
                datePickerConfig: {
                    startDate: new Date(),
                    endDate: new Date(),
                    autoApply: true,
                    ranges: {
                        'Hari Ini': [new Date(), new Date()],
                        '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
                        '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
                    },
                    applyClass: 'btn-sm btn-primary',
                    cancelClass: 'btn-sm btn-light',
                    locale: {
                        applyLabel: 'Terapkan',
                        cancelLabel: 'Batal',
                        direction: 'ltr',
                        format: 'mm/dd/yyyy',
                        separator: ' - ',
                    }
                },
                dateRange: {
                    startDate: new Date(),
                    endDate: new Date(),
                },
                pasienLama: 0,
                pasienBaru: 0,
                openModalFastTrack : false,
                rowEdit : {},
                mAlatBantu: [],
                rowPasien : {},
                tabDraft : 'N',
                listRujukan : [],
                mPoli: [],
                mDokter: [],
                mStatus: [
                    {text : "Semua Status" , value:"ALL"},
                    {text : "Butuh SEP" , value:"need-sep"},
                    {text : "Verifikasi Asuransi" , value:"verif-asuransi"},
                ],
                mUrutkanWaktu: [
                    {
                        text: "Urutkan Waktu Checkin Terbaru",
                        value: "desc_checkin"
                    },
                    {
                        text: "Urutkan Waktu Checkin Terlama",
                        value: "asc_checkin"
                    }
                ],
                openKontrol: false,
                dataRencanaKontrol: {},
                onFilter: false,
                mAsalRujukan: []
            }
        },
  	    components:{DateRangePicker,Form,FormDetail},
        mounted(){
            this.filter.status_pasien = 'ALL'
            this.filter.status_periksa = 'ALL'
            this.filter.status_jaminan = 'ALL'
            this.filter.poli = 'ALL'
            this.filter.status_reservasi = 'need-sep'
            this.filter.dokter = 'ALL'
            this.filter.is_draft = this.tabDraft

            if (this.$route.query.status_reservasi) {
                this.filter.status_reservasi = this.$route.query.status_reservasi
            }

            if (this.$route.query.startDate && this.$route.query.endDate) {
                this.dateRange.startDate = this.$route.query.startDate
                this.dateRange.endDate = this.$route.query.endDate
            }

            this.doConvertDate()      
            this.apiGet()
            
            if(this.isList){
                this.apiGetMasterDanResume()
            }
                
            setTimeout(()=>{
                this.setSlide()
            },1000)
        },
        methods: {
            apiGetResume(){
                let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
                Gen.apiRest(
                    "/get/"+this.modulePage+'/resume', 
                    {
                    params: Object.assign({}, paramsQuery||{})
                    }
                ).then(res=>{
                    _.forEach(res.data, (v,k)=>{
                        this.$set(this, k, v)
                    })
                })
            },
            apiGetMasterDanResume() {
                let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
                Gen.apiRest(
                    "/get/" + this.modulePage + '/master_and_resume',
                    {
                        params: Object.assign({ isList: 'Y' }, paramsQuery || {})
                    }
                ).then(res => {
                    _.forEach(res.data.restMaster, (v, k) => {
                        this.$set(this, k, v)
                    })
                    _.forEach(res.data.restResume, (v, k) => {
                        this.$set(this, k, v)
                    })
                })
            },
            fillDOB(e){
                let val = e.target.value
                if(val.length == 10){
                    let dobdata = val.split("/")
                    let date = moment(dobdata[2]+"-"+dobdata[1]+"-"+dobdata[0]).format('DD/MM/YYYY')
                    let d = new Date()
                    if(date == 'Invalid date'){
                        return this.$swal({
                            icon: 'error',
                            title: 'Tanggal Tidak Valid'
                        })
                    }else{
                        this.filter.dob = dobdata[2]+"-"+dobdata[1]+"-"+dobdata[0]
                        this.doFill()
                    }
                }
            },
            setSlide(){
                const responsiveTable = document.querySelector('.table-responsive')
                if(responsiveTable){
                    const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
                    const responsiveTableScrollActions = responsiveTable.querySelectorAll('.btn')
                    if(responsiveTableScroll){
                        // responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
                        Array.from(responsiveTableScrollActions).forEach(action => {
                            action.addEventListener('click', e => {
                                const scrollAmount = action.dataset.scroll == 'right' ? responsiveTable.scrollLeft + 100 : responsiveTable.scrollLeft - 100
                                 
                                responsiveTable.scroll({
                                    left: scrollAmount,
                                    behavior: 'smooth'
                                })
                            })
                        })
                    }
                }
            },
            windowTableScroller(e){
                const responsiveTable = document.querySelector('.table-responsive')
                if(responsiveTable){
                    const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
                    if(responsiveTableScroll){
                        // responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
                        this.tableScrollPoint = responsiveTable.getBoundingClientRect().top <= 64 ? (responsiveTable.offsetTop * 2) - 64 : null
                        const isTableScrollable = responsiveTable.scrollHeight > responsiveTable.clientHeight
                        if(isTableScrollable && responsiveTable.getBoundingClientRect().top <= 80 && this.tableScrollPoint){
                            this.onScrollPoint = true
                            document.documentElement.scroll(0, this.tableScrollPoint)
                            window.addEventListener('wheel', this.tableScrollFunction, false)
                        }
                    }
                }
            },
            doResetData(){
                this.doReset()
                this.dateRange =  {
                    startDate: new Date(),
                    endDate: new Date(),
                }
                this.doConvertDate()
            },
            doRefreshData(){
                this.apiGet()
                window.scrollTo(0,0)
            },
            changeTab(tab){
                this.tabDraft = tab
                this.filter.is_draft = this.tabDraft
                this.doFill()
            },
            doFastTrack(row){
                this.rowEdit = row
                this.openModalFastTrack = true
            },
            submitFastTrack(){
                this.$refs['VFormFastTrack'].validate().then(success=>{
                    if(!success) return
                    this.rowEdit.type = 'update-fastrack'
                    if(this.rowEdit.arres_is_alat_bantu == 'Y'){
                        this.rowEdit.ar_fast_track_notes = null
                    }else{
                        this.rowEdit.arres_alat_bantu = null
                    }
                    Gen.apiRest(
                        "/do/"+this.modulePage,
                        {data: this.rowEdit}, 
                        "POST"
                    ).then(res=>{
                        this.$swal({
                            title: `Berhasil`,
                            text: 'Berhasil Mengubah Data Pasien',
                            icon: 'success',
                        })
                        this.apiGet()
                        this.openModalFastTrack = false
                    })
                })
            },
            addNewReservasi() {
                let data = {
                    type : 'init-new-reservasi'
                }
                this.loadingOverlay = true

                Gen.apiRest(
                    "/do/"+this.modulePage,
                    {data:data}, 
                    "POST"
                ).then(res=>{
                    this.loadingOverlay = false
                    let data = res.data.data
                    this.$router.push({ name: 'RoReservasi', params: { pageSlug: data.arres_id } })
                    .catch(()=>{})
                })
            },
            toDetail(data){
                this.$router.push({ name: 'RoReservasi', params: { pageSlug: data.arres_id } })
                .catch(()=>{})
            },
            doConvertDate(){
                this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
                this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
            },
            doPageOne(){
                this.filter.page = 1
            },
            doFill(){
                this.onFilter = true
                this.doPageOne()
                this.doFilter()
            },
            updateValues(e){
                this.doConvertDate()
                this.doFill()
            },
            doSearch: _.debounce(function(){
                this.onFilter = true
                this.doPageOne()
                this.doFilter()
            },500),
            getConfigDynamic(master,value){
                let text = ''
                if(value){
                    let index = (master||[]).findIndex(x => x.value == value)
                    if(index !== -1){
                        text = master[index]['text']
                    }
                }
                return text
            },
            doOpenKontrol(row){
                this.openKontrol = true
                let data = {
                    type : 'open-data-kontrol',
                    ap_id : row.ap_id,
                    poli_id : row.ar_mpo_id
                }
                this.loadingOverlay = true

                Gen.apiRest(
                    "/do/"+this.modulePage,
                    {data:data}, 
                    "POST"
                ).then(res=>{
                    this.loadingOverlay = false
                    this.dataRencanaKontrol = res.data.dataRencanaKontrol
                    console.log(this.dataRencanaKontrol)
                }).catch(()=>{})
            },
            doCetakSK(v){
                
                let data = {exptype: 'xlsx', type: "export", id: v.arrk_id}
                let self = this
                
                self.loadingOverlay = true

                let path

                if(v.arres_jaminan == 3){
                    path = "downloadSuratKontrolBPJS"
                }else{
                    path = "downloadSuratKontrol"
                }

                $.ajax({
                    type: "POST",
                    url: process.env.VUE_APP_API_URL + `/report/${path}?token=IXs1029102asoaksoas102901290`,
                    data: data,
                    cache: false,
                    xhrFields:{
                        responseType: 'blob'
                    },
                    success: data => 
                    {
                        self.loadingOverlay = false
                        var link = document.createElement('a')
                        link.href = window.URL.createObjectURL(data)
                        link.download = `Laporan-Rencana-Kontrol-${moment().format("YYYY-MM-DD")}.pdf`
                        link.click()
                    },
                    fail: data => {
                        self.loadingOverlay = false
                        alert('Not downloaded')
                    }
                })
            }
        },
        filters: {
            date(val) {
                return val ? moment(val).format("D MMM YYYY") : ""
            }
        },
        watch:{
            $route(vNew, vOld) {
                this.apiGet()

                if (this.isList && (
                    vNew.params.pageSlug !== vOld.params.pageSlug ||
                    this.onFilter
                )){
                    this.apiGetResume()
                }
                
                setTimeout(()=>{
                    this.onFilter = false
                    
                    if(this.isList){
                        this.setSlide()
                    }
                },1000)
            },
            '$route.params.pageSlug':function(){
                this.$set(this,'filter',{})
            }
        }

    }
</script>