<template>
    <fieldset>
        <div class="wrap_line_heading">
        <h6 class="font-weight-semibold">Data Cara Bayar</h6>
        <div class="data_prev_cont">
            <div class="alert alert-info">
            <h6 class="mb-0"><small>Jenis Konsultasi: <span class="font-weight-semibold">Datang Langsung</span>
                </small> </h6>
            </div>
            <div class="form-row">
            <div class="form-group col-md-12">
                <div class="form-row">
                <div class="col-md-8">
                
                    <div class="row">
                        <div class="form-group col-md-5">
                            <b-form-input v-model="nama_pasien" type="text" name="bahasa" id="bahasa" class="form-control" placeholder="Nama Pasien" />
                        </div>
                        <div class="form-group col-md-5">
                            <b-form-input v-model="no_rm" type="text" name="bahasa" id="bahasa" class="form-control" placeholder="Nomor Rekam Medis" />
                        </div>
                        <div class="form-group col-md-2">
                        <a href="javascript:;" @click="searchPasien()" class="btn-new">Cari</a>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-5">
                            <b-form-input v-model="alamat" type="text" name="bahasa" id="bahasa" class="form-control" placeholder="Alamat" />
                        </div>
                        
                        <div class="form-group col-md-5">
                            <b-form-input v-model="ttl" v-mask="'##/##/####'" type="text" name="bahasa" id="bahasa" class="form-control" placeholder="Tanggal Lahir" />
                        </div>
                        <div class="form-group col-md-2">
                            <a href="javascript:;" @click="resetPasien()" class="btn-new">Reset</a>
                        </div>

                    </div>

                    <label>Nama Pasien <small class="txt_mandatory">*</small></label>

                    <v-select class="diagnosa-section select-paging" placeholder="-- Pilih Pasien -- "
                        v-model="isParent.row.arres_ap_id"
                        :disabled="isParent.row.arres_is_draft != 'Y'"
                        @search="filterName($event)"
                        @input="selectName($event)"
                        :options="isParent.mPasien" label="text"
                        :clearable="true" :reduce="v=>v.value">
                        
                        <template slot="selected-option">
                        <span v-b-tooltip.hover.right :title="isParent.row.ap_fullname">
                            {{isParent.row.ap_fullname||"-"}} - {{isParent.row.ap_code||"-"}}
                            <template v-if="isParent.row.ap_dob"> 
                            -
                            {{isParent.row.ap_dob | moment("DD MMMM YYYY")}}
                            </template>
                            <template v-else>
                            -
                            Tgl Lahir Kosong
                            </template>
                            - {{isParent.row.ap_dom_address||"Alamat Kosong"}}
                        </span>
                        </template>
                        
                        <template slot="option" slot-scope="option">
                         <span v-b-tooltip.hover.right :title="option.text">
                         {{ option.text }}
                         </span>
                        </template>
                        
                        <template #list-footer v-if="(isParent.mPasien||[]).length >= 1">
                        <li class="list-paging">
                            <div class="d-flex align-items-center justify-content-between">
                            <b-button @click="changePage(isParent.mPasien[0].currentPage,'min')"
                                size="sm" variant="transparent" class="btn-icon bg-blue-300"><i
                                class="icon-arrow-left22 text-white"></i></b-button>
                            <small>Halaman {{isParent.mPasien[0].currentPage}} dari
                                {{ceilData(isParent.mPasien[0].totalRows/10)}}</small>
                            <b-button @click="changePage(isParent.mPasien[0].currentPage,'plus')"
                                size="sm" variant="transparent" class="btn-icon bg-blue-300"><i
                                class="icon-arrow-right22 text-white"></i></b-button>
                            </div>
                        </li>
                        </template>
                    </v-select>
                    
                    <VValidate 
                        name="Nama Pasien" 
                        v-model="isParent.row.arres_ap_id" 
                        :rules="toValidate(isParent.mrValidation.arres_ap_id)"
                    />
                </div>
                <template v-if="isParent.row.arres_is_draft != 'N'">
                <div class="col-md-3">
                    <a href="javascript:;" @click="addNewPasien" class="btn-new"><i class="icon-user-plus"></i> Tambah
                    Pasien Baru </a>
                    
                    <a href="javascript:;" @click="modalGenerate=true" class="btn-new mt-2"><i class="icon-user-plus"></i> Generate
                    Pasien Baru </a>
                </div>
                </template>
                </div>
            </div>
            </div>

    
            <template v-if="Object.keys(rowPasien||{}).length">
                <div class="card mb-1">
                    <div class="card-body bg_card_blue">
                        <div class="row">
                        <div class="col-md-9">
                            <table class="table table-borderless table-sm text-uppercase">
                            <tbody>
                                <tr>
                                <td width="33%">
                                    <div class="result_tab">
                                    <h4>No. Rekam Medis</h4>
                                    <p>{{rowPasien.ap_code||"-"}}</p>
                                    </div>
                                </td>
                                <td width="33%">
                                    <div class="result_tab">
                                    <h4>Nama Pasien</h4>
                                    <p>{{rowPasien.ap_fullname||"-"}}</p>
                                    </div>
                                </td>
                                </tr>
                                <tr>
                                <td width="33%">
                                    <div class="result_tab">
                                    <h4>Tempat, Tanggal Lahir </h4>
                                    <p>{{rowPasien.ap_pob||"-"}}, {{rowPasien.ap_dob | moment("DD MMMM YYYY")}}</p>
                                    </div>
                                </td>
                                <td>
                                    <div class="result_tab">
                                    <h4>Usia</h4>
                                    <p>{{rowPasien.ap_usia_with_ket||"-"}}</p>
                                    </div>
                                </td>
                                <td>
                                    <div class="result_tab">
                                    <h4>Jenis Kelamin</h4>
                                    <p>{{rowPasien.cg_label||"-"}}</p>
                                    </div>
                                </td>
                                <td>
                                    <div class="result_tab">
                                    <h4>Alamat</h4>
                                    <p>{{rowPasien.ap_dom_address||"-"}}</p>
                                    </div>
                                </td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                        </div>
                    </div>
                </div>
            </template>
            
            <div class="row mt-1">
                <div class="form-group col-md-4">
                    <label>Asal Rujukan <small class="txt_mandatory">*</small></label>
                    <v-select placeholder=" -- Pilih Asal Rujukan -- " v-model="isParent.row.arres_asal_rujukan"
                        :options="isParent.mAsalRujukan" label="text" :clearable="true"
                        :reduce="v=>v.value">
                    </v-select>
                    <VValidate 
                        name="Asal Rujukan" 
                        v-model="isParent.row.arres_asal_rujukan" 
                        :rules="toValidate(isParent.mrValidation.arres_asal_rujukan)"
                    />
                </div>
                <div class="col-md-4 pt-3">
                  <a href="javascript:;" @click="modalNewRujuk=true" class="btn-new mt-2"><i class="icon-add"></i> Tambah Asal Rujukan Baru </a>
                </div>

            </div>
            
            <div class="row mt-1">
                <div class="form-group col-md-4">
                    <label>Jaminan <small class="txt_mandatory">*</small></label>
                    <v-select :disabled="!isParent.row.arres_ap_id?true:false" @input="changeJaminan()" placeholder=" -- Pilih Jaminan -- " v-model="isParent.row.arres_jaminan"
                        :options="Config.mr.mJaminan" label="text" :clearable="true"
                        :reduce="v=>v.value">
                    </v-select>
                    <VValidate 
                        name="Jaminan" 
                        v-model="isParent.row.arres_jaminan" 
                        :rules="toValidate(isParent.mrValidation.arres_jaminan)"
                    />
                </div>
                
                <div class="form-group col-md-4" v-if="isParent.row.arres_jaminan == 3">
                    <label>Asal Rujukan BPJS <small class="txt_mandatory">*</small></label>
                    <v-select @input="changeJaminan()" placeholder=" -- Pilih Asal Rujukan BPJS -- " v-model="isParent.row.arres_type_rujukan"
                        :options="Config.mr.jenisRujukan" label="text" :clearable="true"
                        :reduce="v=>v.value">
                    </v-select>
                    <VValidate 
                        name="Asal Rujukan BPJS" 
                        v-model="isParent.row.arres_type_rujukan" 
                        :rules="toValidate(isParent.mrValidation.arres_type_rujukan)"
                    />
                </div>
                
                <div class="form-group col-md-4" v-if="isParent.row.arres_jaminan == 3 && isParent.row.arres_type_rujukan == 2">
                    <label>Jenis Dokumen<small class="txt_mandatory">*</small></label>
                    <v-select @input="changeJaminan();isParent.row.arres_no_rujukan=null" placeholder=" -- Pilih Jenis Dokumen -- " v-model="isParent.row.arres_jenis_dokumen"
                        :options="Config.mr.jenisDokumen" label="text" :clearable="true"
                        :reduce="v=>v.value">
                    </v-select>
                    <VValidate 
                        name="Jenis Dokumen" 
                        v-model="isParent.row.arres_jenis_dokumen" 
                        :rules="{required : 1}"
                    />
                </div>

                <template v-if="!errData">
                <div class="col-12" v-if="isParent.row.arres_jaminan == 3">
                    <div class="row">
                    <div class="form-group col-md-3">
                        <label>Nomor Peserta BPJS <small class="txt_mandatory">*</small></label>
                        <b-form-input :disabled="isParent.row.ap_kewarganegaraan=='WNA'?false:true" v-model="isParent.row.arres_no_bpjs" :formatter="isParent.$parent.number" type="text" name="bahasa" id="bahasa" class="form-control" placeholder="Nomor Peserta BPJS" />
                        <VValidate 
                            name="Nomor Peserta BPJS" 
                            v-model="isParent.row.arres_no_bpjs" 
                            :rules="toValidate(isParent.mrValidation.arres_no_bpjs)"
                        />
                    </div>
                    <div class="form-group col-md-6">
                        <label>{{isParent.row.arres_jenis_dokumen == 'K' ? 'Nomor Surat Kontrol': 'Nomor Rujukan'}} <small class="txt_mandatory">*</small></label>
                        <v-select placeholder=" -- Pilih Rujukan -- " v-model="isParent.row.arres_no_rujukan"
                            :options="isParent.listRujukan" label="text" :clearable="true"
                            :reduce="v=>v.value"
                            @input="inputNoRujuk($event)"    
                        >
                        </v-select>
                        <VValidate 
                            :name="isParent.row.arres_jenis_dokumen == 'K' ? 'Nomor Surat Kontrol' : 'Nomor Rujukan'" 
                            v-model="isParent.row.arres_no_rujukan" 
                            :rules="toValidate(isParent.mrValidation.arres_no_rujukan)"
                        />
                    </div>
                    
                    <div class="form-group col-md-3" v-if="isParent.row.arres_no_skdp">
                        <label>Nomor SKDP</label>
                        <b-form-input disabled v-model="isParent.row.arres_no_skdp" type="text" name="bahasa" id="bahasa" class="form-control" placeholder="Nomor SKDP" />
                    </div>
                    
                    <!--
                    <div class="form-group col-md-3">
                        <label>Nomor Rujukan Internal</label>
                        <b-form-input disabled v-model="isParent.row.arres_no_sep_internal" type="text" name="bahasa" id="bahasa" class="form-control" placeholder="Nomor Rujukan" />
                    </div>
                    -->
                    
                    <div class="col-md-12">
                        <div class="table-responsive mb-1">
                        <table class="table table-bordered mb-0">
                            <thead>
                            <tr>
                                <th width="180">Keterangan </th>
                                <th class="bg-primary">Data RS</th>
                                <th class="bg-success">Data BPJS</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <th class="table-secondary">
                                <div class="wrap_preview_data">
                                    <span class="font-weight-semibold">Nama Pasien</span>
                                </div>
                                </th>
                                <td>
                                <div class="wrap_preview_data">
                                    <p>{{isParent.row.ap_fullname||"-"}}</p>
                                </div>
                                </td>
                                <td>
                                <div class="wrap_preview_data">
                                    <p>{{isParent.row.apbd_fullname||"-"}}
                                        <span id="bpjs_status" class="badge badge-info">{{isParent.row.apbd_status}}</span>
                                    </p>
                                </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="table-secondary">
                                <div class="wrap_preview_data">
                                    <span class="font-weight-semibold">NIK KTP</span>
                                </div>
                                </th>
                                <td>
                                <div class="wrap_preview_data">
                                    <p>{{isParent.row.ap_nik||"-"}}</p>
                                </div>
                                </td>
                                <td>
                                <div class="wrap_preview_data">
                                    <p>{{isParent.row.apbd_nik||"-"}}</p>
                                </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="table-secondary">
                                <div class="wrap_preview_data">
                                    <span class="font-weight-semibold">Jenis Kelamin</span>
                                </div>
                                </th>
                                <td>
                                <div class="wrap_preview_data">
                                    <p>{{getConfigDynamic(Config.mr.StatusKelamin,isParent.row.ap_gender)||"-"}}</p>
                                </div>
                                </td>
                                <td>
                                <div class="wrap_preview_data">
                                    <p>{{getConfigDynamic(Config.mr.StatusKelamin,isParent.row.apbd_gender)||"-"}}</p>
                                </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="table-secondary">
                                <div class="wrap_preview_data">
                                    <span class="font-weight-semibold">Tanggal Lahir</span>
                                </div>
                                </th>
                                <td>
                                <div class="wrap_preview_data">
                                    <p v-if="isParent.row.ap_dob">{{ isParent.row.ap_dob | moment("DD MMMM YYYY")}}</p>
                                    <p v-else> - </p>
                                </div>
                                </td>
                                <td>
                                <div class="wrap_preview_data">
                                    <p v-if="isParent.row.apbd_dob">{{ isParent.row.apbd_dob | moment("DD MMMM YYYY")}}</p>
                                    <p v-else> - </p>
                                </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                    </div>
                </div>
                </template>

                <div class="form-group col-md-4" v-if="isParent.row.arres_jaminan == 2">
                    <label>Pilihan Asuransi <small class="txt_mandatory">*</small></label>
                    <v-select @input="isParent.row.arres_file_asuransi = null" :disabled="!isParent.row.arres_ap_id?true:false" placeholder="Pilih Jenis Asuransi" v-model="isParent.row.arres_asuransi"
                        :options="isParent.mAsuransi" label="text" :clearable="true"
                        :reduce="v=>v.value">
                    </v-select>
                    <VValidate 
                        name="Asuransi" 
                        v-model="isParent.row.arres_asuransi" 
                        :rules="toValidate(isParent.mrValidation.arres_asuransi)"
                    />
                    
                </div>
                
                <div class="form-group col-md-4" v-if="isParent.row.arres_jaminan == 2">
                    <label>No Kartu Asuransi <small class="txt_mandatory">*</small></label>
                    <b-form-input v-model="isParent.row.arres_no_kartu_asuransi" :formatter="$parent.normalText" type="text" class="form-control"/>
                    <VValidate 
                        name="No. Kartu Asuransi" 
                        v-model="isParent.row.arres_no_kartu_asuransi" 
                        :rules="{required: 1}"
                    />
                </div>
                
                <div class="form-group col-md-4" v-if="isParent.row.arres_jaminan == 2">
                    <label>No Polis</label>
                    <b-form-input v-model="isParent.row.arres_no_polis" :formatter="$parent.normalText" type="text" class="form-control"/>
                </div>

                <template v-if="errData">
                    <div class="col-md-12">
                        <div class="alert alert-danger">
                        <h6 class="mb-0">{{errData||"-"}}</h6>
                        </div>
                    </div>
                </template>

                
                <template v-if="errDataV2">
                    <div class="col-md-12">
                        <div class="alert alert-danger">
                        <h6 class="mb-0">{{errDataV2||"-"}}</h6>
                        </div>
                    </div>
                </template>

            </div>
        </div>
        </div>
        <hr>

        <div class="wrap_line_heading mb-4" v-if="!errData">
            <h6 class="font-weight-semibold">Dokumen Pendukung</h6>
            <p>Mohon gambar/foto yang di upload bisa terbaca dengan jelas</p>

            <div class="form-row" id="docUmum" v-if="isParent.row.arres_jaminan == 1 || isParent.row.arres_jaminan == 2">
                <div class="col-md-3" v-if="isParent.row.arres_jaminan == 1 || isParent.row.arres_jaminan == 2">
                <div class="form-group">
                    <label for="">KTP/Kartu Keluarga</label>
                    <Uploader isDocument v-model="isParent.row.arres_file_ktp" type="docimage"/>  
                </div>
                </div>

                <div class="col-md-3" v-if="isParent.row.arres_jaminan == 1 || isParent.row.arres_jaminan == 2">
                <div class="form-group">
                    <label for="">Foto Pasien</label>
                    <Uploader isDocument v-model="isParent.row.arres_file_foto_pasien" type="image"/>  
                </div>
                </div>
                
                <div class="col-md-3" v-if="isParent.row.arres_jaminan == 2">
                <div class="form-group">
                    <label for="">Kartu Asuransi</label>
                    <Uploader isDocument v-model="isParent.row.arres_file_asuransi" type="docimage"/>  
                </div>
                </div>
            </div>
            
            <div class="form-row"  v-if="isParent.row.arres_jaminan == 3">
                <div class="col-md-3">
                <div class="form-group">
                    <label for="">Kartu BPJS</label>
                    <Uploader isDocument v-model="isParent.row.arres_kartu_bpjs" type="docimage"/>  
                </div>
                </div>

                <div class="col-md-3">
                <div class="form-group">
                    <label for="">Surat Rujukan</label>
                    <Uploader isDocument v-model="isParent.row.arres_surat_rujukan" type="docimage"/>  
                </div>
                </div>

                <div class="col-md-3" v-if="isParent.row.arres_no_skdp">
                <div class="form-group">
                    <label for="">Surat Kontrol</label>
                    <Uploader isDocument v-model="isParent.row.arres_surat_kontrol" type="docimage"/>  
                </div>
                </div>
            </div>
        </div>


        <validation-observer ref="VFormGenerateRM">
        <b-modal v-model="modalGenerate" :title="'Generate Nomor RM'" size="md" @ok.prevent="generateNomorRM">
            <div class="modal-body p-0">
                <div class="row mb-2">
                    <div class="col-md-12">
                        <b-form-group class="mb-0">
                        
                        <label for="">Masukkan Nomor Rekam Medis<span class="text-danger">*</span></label>
                        <b-form-input :formatter="isParent.$parent.numberOnly" placeholder="Nomor Rekam Medis" type="text" class="form-control" v-model="nomorGenerate"/>
                        
                        <VValidate 
                            name="Nomor Rekam Medis" 
                            v-model="nomorGenerate" 
                            :rules="{required: 1, min: 15, max: 15}"
                        />
                        
                        </b-form-group>
                    </div>
                </div>
            </div>
        </b-modal>
        </validation-observer>

        
        <validation-observer ref="VFormNewAsal">
        <b-modal v-model="modalNewRujuk" :title="'Tambah Asal Rujukan Pasien'" size="sm" @ok.prevent="submitNewAsalRujukan">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <b-form-input v-model="asalRujukanName" type="text" name="bahasa" id="bahasa" class="form-control" placeholder="Asal Rujukan" />
                        <VValidate 
                            name="Asal Rujukan" 
                            v-model="asalRujukanName" 
                            :rules="{required: 1, min: 3, max: 128}"
                        />

                    </div>
                </div>  
            </div>
        </b-modal>
        </validation-observer>

    </fieldset>
</template>

<script>

import Config from '@/libs/Config'
import Gen from '@/libs/Gen.js'
const _ = global._
const moment = require('moment')


export default {
    
    computed: {    
        Config(){ return Config },
        isParent(){
            return this.$parent.$parent.$parent.$parent
        }
    }, 
    data() {
        return {
            searchData: null,
            errData: "",
            errDataV2: "",
            rowPasien: {},
            modalGenerate: false,
            nomorGenerate: null,
            
            nama_pasien: null,
            no_rm: null,
            alamat: null,
            ttl: null,
            modalNewRujuk: false,
            asalRujukanName: ''
        }
    },
    mounted(){
        setTimeout(()=>{
            this.rowPasien = this.isParent.rowPasien  
            this.inputNoRujuk(this.isParent.row.arres_no_rujukan)
        },1000)
        
        setTimeout(()=>{
            this.changeJaminan(true)
        },1500)

    },
    methods: {
        submitNewAsalRujukan(){
            this.$refs['VFormNewAsal'].validate().then(success=>{
                if(success){
                    this.$swal({
                        icon: 'warning',
                        title: 'Apakah Anda Yakin akan menambahkan Asal Rujukan Baru?',
                        showCancelButton: true,
                        confirmButtonText: 'Ya',
                        cancelButtonText: 'Tidak, kembali'
                    }).then(result => {
                        if (result.value) {
                            this.isParent.$parent.loadingOverlay = true
                            Gen.apiRest('/do/' + 'RoReservasi', {
                                data: {
                                    type: 'add-new-asal-rujukan',
                                    reservasi_id: this.isParent.row.arres_id,
                                    asal_rujukan: this.asalRujukanName,
                                }
                            }, 'POST').then(res => {
                                this.modalNewRujuk = false,
                                this.asalRujukanName = ''
                                this.isParent.$parent.loadingOverlay = false
                                this.isParent.apiGetMaster()
                                this.isParent.$parent.apiGet()
                            }).catch(err => {
                                this.isParent.$parent.loadingOverlay = false
                                if (err) {
                                    err.statusType = err.status
                                    err.status = "error"
                                    err.title = err.response?.data?.title
                                    err.message = err.response?.data?.message
                                    err.messageError = err.message
                                }
                                this.isParent.$parent.doSetAlertForm(err)
                            })
                        }
                    })
                }
                
            })
        },
        resetPasien(){
            this.nama_pasien = null
            this.no_rm = null
            this.alamat = null
            this.ttl = null
        },
        searchPasien(){

            let dateTTL = null
            if(this.ttl){
                if(this.ttl.length == 10){
                    let dobdata = this.ttl.split("/")
                    let date = moment(dobdata[2]+"-"+dobdata[1]+"-"+dobdata[0]).format('DD/MM/YYYY')
                    let d = new Date()
                    if(date == 'Invalid date' || dobdata[2] > d.getFullYear()){
                        return this.$swal({
                            icon: 'error',
                            title: 'Tanggal Tidak Valid'
                        })
                    }

                    dateTTL = dobdata[2]+"-"+dobdata[1]+"-"+dobdata[0]
                }else{
                    return this.$swal({
                        icon: 'error',
                        title: 'Tanggal Tidak Valid'
                    })
                }
            }

            this.isParent.$parent.loadingOverlay = true
            Gen.apiRest('/do/' + 'RoReservasi', {
                data: {
                    type: 'select-paging-pasien',
                    nama_pasien: this.nama_pasien,
                    no_rm: this.no_rm,
                    alamat: this.alamat,
                    ttl: dateTTL,
                }
            }, 'POST').then(res => {
                this.isParent.row.arres_ap_id = null
                this.isParent.mPasien = res.data.data
                this.isParent.$parent.loadingOverlay = false
            })
        },
        
        inputNoRujuk(e){
            let data = {
                no_rujuk : e,
                type : 'cek-nomor-rujukan',
                arres_id : this.isParent.row.arres_id
            }
            this.isParent.$parent.loadingOverlay = true
            Gen.apiRest(
                "/do/"+this.isParent.$parent.modulePage, 
                {
                    data: data
                }, "POST"
            ).then(res=>{
                this.isParent.$parent.loadingOverlay = false  
                this.isParent.row.arres_start_kontrol = res.data.allow_kontrol  

                let index = this.isParent.listRujukan.findIndex(x => x.value == e)
                if(index != -1){
                    this.isParent.row.arres_tanggal_rujukan = this.isParent.listRujukan[index]['tgl']  
                }

            })
        },
        generateNomorRM(){
            this.$refs['VFormGenerateRM'].validate().then(success=>{
                if(success){
                    let data = {
                        nomorRM : this.nomorGenerate,
                        type : 'generate-nomor-rm',
                        arres_id : this.isParent.row.arres_id
                    }
                    this.isParent.$parent.loadingOverlay = true
                    
                    Gen.apiRest(
                        "/do/"+this.isParent.$parent.modulePage, 
                        {
                            data: data
                        }, "POST"
                    ).then(res=>{
                        this.isParent.$parent.loadingOverlay = false
                        let resp = res.data
                        resp.statusType = 200
                        this.isParent.$swal({
                            title: resp.message,
                            icon: resp.status,
                            confirmButtonText: 'Ok',
                            allowOutsideClick: false,
                            allowEscapeKey: false
                        }).then(result => {
                            if (result.value) {   
                                this.nomorGenerate = null
                                this.modalGenerate = false
                                this.isParent.$parent.apiGet()
                            }
                        }) 
                    }).catch(err => {
                        this.isParent.$parent.loadingOverlay = false
                        if (err) {
                            err.statusType = err.status
                            err.status = "error"
                            err.title = err.response?.data?.title
                            err.message = err.response?.data?.message
                            err.messageError = err.message
                        }
                        this.isParent.$parent.doSetAlertForm(err)
                    })
                }
            })
        },
        changeJaminan(isLoad=false){
            this.errData = ""
            this.errDataV2 = ""


            // if(!isLoad){
            //     this.isParent.row['arres_type'] = null
            // }
            

            if(this.isParent.row.arres_jaminan == 3 && this.isParent.row.arres_type_rujukan){
                
                if((this.isParent.row.ap_nik).length < 16){
                    return this.isParent.$swal({
                        title: `Nomor NIK tidak Valid`,
                        text: 'Silakan update Nomor NIK di menu pasien',
                        icon: 'error',
                    })
                }
                
                let data = {
                    ap_id : this.isParent.row.arres_ap_id,
                    nik : this.isParent.row.ap_nik,
                    nokartu : this.isParent.row.arres_no_bpjs,
                    kw: this.isParent.row.ap_kewarganegaraan,
                    type : 'select-bpjs-value',
                    arres_type_rujukan : this.isParent.row.arres_type_rujukan,
                    arres_jenis_dokumen : this.isParent.row.arres_jenis_dokumen,
                }
                this.isParent.$parent.loadingOverlay = true
                
                Gen.apiRest(
                    "/do/"+this.isParent.$parent.modulePage, 
                    {
                        data: data
                    }, "POST"
                ).then(res=>{
                    let data = res.data.data
                    
                    if(res.data.status == "fail"){
                        this.isParent.isBPJS = false
                        this.isParent.$parent.loadingOverlay = false
                        this.errData = "NIK atau nomor kartu anda tidak ditemukan pada data BPJS. Silakan periksa NIK atau nomor kartu anda."
                        return
                    }

                    this.isParent.isBPJS = true
                    
                    this.isParent.row.apbd_fullname = data.apbd_fullname
                    this.isParent.row.apbd_nik = data.apbd_nik
                    this.isParent.row.apbd_gender = data.apbd_gender
                    this.isParent.row.apbd_dob = data.apbd_dob
                    this.isParent.row.apbd_status = data.apbd_status
                    this.isParent.row.arres_type = this.isParent.row.arres_type ? this.isParent.row.arres_type : 'Poli'
                    this.isParent.row.arres_no_bpjs = data.apbd_no_bpjs
                    this.isParent.listRujukan = data.listRujukan

                    if(!(this.isParent.listRujukan||[]).length && !this.isParent.row.arres_no_rujukan){
                        this.isParent.row.arres_no_rujukan = null
                    }

                    let statusBPJS = data.apbd_status
                    if(statusBPJS == 'MENINGGAL' || statusBPJS == 'KELUAR KEMAUAN SENDIRI' || statusBPJS == 'TIDAK DITANGGUNG' || statusBPJS == 'PENANGGUHAN PESERTA' || statusBPJS == 'NON AKTIF DIAKHIR BULAN' || statusBPJS == 'PENANGGUHAN PEMBAYARAN' || statusBPJS == 'NON AKTIF KARNA DATA GANDA' || statusBPJS == 'USIA ANAK PPU > 21 TH DAN 25 TH' || statusBPJS == 'NON AKTIF KARNA PREMI'){
                        this.errDataV2 = data.apbd_status
                    }else{
                        this.errDataV2 = ""
                    }
                    this.isParent.$parent.loadingOverlay = false
                }).catch(err=>{
                    this.isParent.$parent.loadingOverlay = false
                })
            }else{
                this.isParent.row.arres_start_kontrol = null
            }

            
            let dataPost = {
                type : "select-poli-bpjs",
                isBPJS : this.isParent.row.arres_jaminan == 3 ? 1 : 0,
            }
            
            Gen.apiRest(
                "/do/"+this.isParent.$parent.modulePage, 
                {
                    data: dataPost
                }, "POST"
            ).then(res=>{
                let resp = res.data
                this.isParent.mPoli = resp.mPoli
            })


        },
        ceilData(value) {
            return Math.ceil(value)
        },
        toValidate(val){
            return {...val}
        },
        addNewPasien(){
            let data = {
                type : 'init-new-pasien'
            }
            this.loadingOverlay = true
            Gen.apiRest(
                "/do/"+'RoPasienNew',
                {data:data}, 
                "POST"
            ).then(res=>{
                this.loadingOverlay = false
                let data = res.data.data
                this.$router.push({ name: 'RoPasienNew', params: { pageSlug: data.ap_id } }).catch(()=>{})
            })
        },
        filterName: _.debounce(function (e) {
            this.searchData = e
            Gen.apiRest('/do/' + 'RoReservasi', {
                data: {
                    type: 'select-paging-pasien',
                    search: e,
                    nama_pasien: this.nama_pasien,
                    no_rm: this.no_rm,
                    alamat: this.alamat
                }
            }, 'POST').then(res => {
                this.isParent.mPasien = res.data.data
            })
        },10),
        selectName(e){
            if(e){
                let index = this.isParent.mPasien.findIndex(x => x.value == e)
                let data = {
                    type : "search-pasien",
                    arres_id : this.isParent.row.arres_id,
                    search : e,
                }

                Gen.apiRest(
                    "/do/"+this.isParent.$parent.modulePage,
                    {data: data}, 
                    "POST"
                ).then(res=>{
                    if(!res.data.data){
                        return this.isParent.$swal({
                            title: `Data Tidak Ditemukan`,
                            text: 'Data Pasien Tidak Ditemukan',
                            icon: 'error',
                        })
                    }else{
                        let data = res.data.data
                        this.rowPasien = data
                        if (index !== -1) {
                            this.isParent.row.ap_fullname =  this.isParent.mPasien[index]['name']
                            this.isParent.row.ap_nik =  this.isParent.mPasien[index]['nik']
                            this.isParent.row.ap_phone_number =  this.isParent.mPasien[index]['ap_phone_number']
                            this.isParent.row.ap_dom_address =  this.isParent.mPasien[index]['ap_dom_address']
                            this.isParent.row.ap_dom_rt =  this.isParent.mPasien[index]['ap_dom_rt']
                            this.isParent.row.ap_dom_rw =  this.isParent.mPasien[index]['ap_dom_rw']
                            this.isParent.row.ap_dom_kelurahan =  this.isParent.mPasien[index]['ap_dom_kelurahan']
                            this.isParent.row.ap_dom_kecamatan =  this.isParent.mPasien[index]['ap_dom_kecamatan']
                            this.isParent.row.ap_dom_kota =  this.isParent.mPasien[index]['ap_dom_kota']
                            this.isParent.row.ap_code = this.isParent.mPasien[index]['code']
                            this.isParent.row.ap_gender = this.isParent.mPasien[index]['ap_gender']
                            this.isParent.row.ap_dob = this.isParent.mPasien[index]['ap_dob']

                            console.log(this.rowPasien.arres_file_ktp)
                            this.isParent.row.arres_file_ktp = this.rowPasien.arres_file_ktp
                            this.isParent.row.arres_file_foto_pasien = this.rowPasien.arres_file_foto_pasien
                            this.isParent.row.arres_no_kartu_asuransi = this.rowPasien.arres_no_kartu_asuransi
                        }else{
                            this.isParent.row.ap_fullname =  null
                            this.isParent.row.ap_nik =  null
                            this.isParent.row.ap_phone_number =  null
                            this.isParent.row.ap_dom_address =  null
                            this.isParent.row.ap_dom_rt =  null
                            this.isParent.row.ap_dom_rw =  null
                            this.isParent.row.ap_dom_kelurahan =  null
                            this.isParent.row.ap_dom_kecamatan =  null
                            this.isParent.row.ap_dom_kota =  null
                            this.isParent.row.ap_code = null
                            this.isParent.row.ap_gender = null
                            this.isParent.row.ap_dob = null

                            this.isParent.row.arres_file_ktp = null
                            this.isParent.row.arres_file_foto_pasien = null
                            this.isParent.row.arres_no_kartu_asuransi = null
                        }

                        this.isParent.row.ap_kewarganegaraan = data.ap_kewarganegaraan
                        this.changeJaminan()
                    }
                })
            }
        },
        changePage(page,act){
            if (act == 'min') {
                if (page > 1) {
                    page -= 1
                    this.isParent.mPasien[0]['currentPage'] -= 1
                }
            } else {
                if (page < this.ceilData(this.isParent.mPasien[0]['totalRows'] / 10)) {
                    page += 1
                    this.isParent.mPasien[0]['currentPage'] += 1
                }
            }
            let data = {
                type: 'select-paging-pasien',
                page: page,
                search: this.searchData,
                nama_pasien: this.nama_pasien,
                no_rm: this.no_rm,
                alamat: this.alamat
            }
            Gen.apiRest(
                "/do/" + 'RoReservasi', {
                    data: data
                },
                "POST"
            ).then(res => {
                this.isParent.mPasien = res.data.data
            })
        },
        getConfigDynamic(master, value, val = 'text') {
            let text = ''
            if (value) {
                let index = (master||[]).findIndex(x => x.value == value)
                if (index !== -1) {
                text = master[index][val]
                }
            }
            return text
        },
    },
}
</script>