<template>
    <fieldset>
        <div class="wrap_line_heading">
        <h6 class="font-weight-semibold">Data Pendaftaran</h6>
        <div class="row">
            <div class="form-group col-md-12">
                <label>Apakah Pasien Mengalami Kecelakaan? <small class="txt_mandatory">*</small></label>
                
                <b-form-radio-group
                    :options="Config.mr.yesNoOptV2"
                    @input="isParent.row.arres_file_kecelakaan=null;isParent.row.isFile = false"
                    v-model="isParent.row['arres_is_kecelakaan']"
                />
                <VValidate 
                    name="Status Kecelakaan" 
                    v-model="isParent.row.arres_is_kecelakaan" 
                    :rules="toValidate(isParent.mrValidation.arres_is_kecelakaan)"
                />

                <div v-if="isParent.row.arres_is_kecelakaan == 'Y'" class="mt-3  row">
                <div class="col-md-10">
                <div class="row">
                <div class="col-md-4">
                   <a @click="isParent.row.isFile = true" :href="isParent.$parent.uploader('SURAT_PERNYATAAN_KRONOLOGI_KEJADIAN.docx')" target="_blank" class="link_download"><i class="icon-download"></i> Download Form Kronologi</a>
                </div>

                  <div class="col-md-6" v-if="isParent.row.isFile == true" style="margin-top:-10px;">
                        <div class="form-group">
                            <label for="">Kronologi Kecelakaan <strong class="txt_mandatory">*</strong></label> <Uploader isDocument v-model="isParent.row.arres_file_kecelakaan" type="docimage"/>  
                            <VValidate 
                                name="Kronologi Kecelakaan" 
                                v-model="isParent.row.arres_file_kecelakaan" 
                                :rules="toValidate(isParent.mrValidation.arres_file_kecelakaan)"
                            />  
                        </div>
                    </div>
              </div>
                </div>
                </div>
            </div>

            <div class="form-group col-md-3">
                <label>Pemeriksaan <small class="txt_mandatory">*</small></label>
                <b-form-radio-group
                    :disabled="isParent.row.arres_is_draft != 'Y'"
                    :options="Config.mr.statusPemeriksaanOpt"
                    v-model="isParent.row['arres_type']"
                    @input="isParent.row.arres_jadwal_dokter_id=null;isParent.row.arres_jenis_penunjang=null"
                />
                <VValidate 
                    name="Pemeriksaan" 
                    v-model="isParent.row.arres_type" 
                    :rules="toValidate(isParent.mrValidation.arres_type)"
                />
            </div>
            <div class="col-12" id="formOptPemeriksaan">
            <div class="row">
                <div class="form-group col-md-3">
                <label>Tanggal Periksa <small class="txt_mandatory">*</small></label>
                <div class="input-group">
                    <datepicker input-class="form-control transparent"
                        :disabledDates="disabledDates"
                        placeholder="Tanggal Periksa" class="my-datepicker"
                        calendar-class="my-datepicker_calendar"
                        v-model="isParent.row.arres_tanggal_periksa"
                        @input="selectDokter(isParent.row.arres_poli,isParent.row.arres_waktu_periksa,isParent.row.arres_tanggal_periksa)"
                        @changedMonth="checkDateIsLibur($event)"
                        >
                    </datepicker>
                    <div class="input-group-append calendar-group">
                        <span class="input-group-text" id="basic-addon2"><i
                                class="icon-calendar"></i></span>
                    </div>
                </div>
                
                <VValidate 
                    name="Tanggal Periksa" 
                    v-model="isParent.row.arres_tanggal_periksa" 
                    :rules="toValidate(isParent.mrValidation.arres_tanggal_periksa)"
                />
                </div>

                <div class="form-group col-md-3" v-if="isParent.row.arres_type == 'Poli' || isParent.row.arres_jenis_penunjang == 'FISIO'">
                <label>Waktu Periksa <small class="txt_mandatory">*</small></label>
    
                <v-select placeholder="Waktu Periksa" v-model="isParent.row.arres_waktu_periksa"
                    :options="Config.mr.mWaktuPeriksa" @input="selectDokter(isParent.row.arres_poli,isParent.row.arres_waktu_periksa,isParent.row.arres_tanggal_periksa)" label="text" :clearable="true"
                    :reduce="v=>v.value"></v-select>
                
                <VValidate 
                    name="Waktu Periksa" 
                    v-model="isParent.row.arres_waktu_periksa" 
                    :rules="toValidate(isParent.mrValidation.arres_waktu_periksa)"
                />
                </div>
                
                <div class="w-100"></div>

                
                <div v-if="isParent.row.arres_type == 'Poli' || isParent.row.arres_jenis_penunjang == 'FISIO'" class="form-group col-md-4">
                    <label>Poliklinik<small class="txt_mandatory">*</small></label>
                    <v-select placeholder="Poli klinik" 
                    :disabled="isParent.row.arres_jenis_penunjang == 'FISIO'"
                    v-model="isParent.row.arres_poli"
                    :options="isParent.mPoli" label="text" @input="selectDokter(isParent.row.arres_poli,isParent.row.arres_waktu_periksa,isParent.row.arres_tanggal_periksa)" :clearable="true"
                    :reduce="v=>v.value"></v-select>
                    <VValidate 
                        name="Poliklinik" 
                        v-model="isParent.row.arres_poli" 
                        :rules="toValidate(isParent.mrValidation.arres_poli)"
                    />
                </div>

                <div class="form-group col-md-4" v-if="isParent.row.arres_type == 'Poli' || isParent.row.arres_jenis_penunjang == 'FISIO'">
                    <label>Dokter<small class="txt_mandatory">*</small></label>
                    <v-select placeholder="Pilih Dokter" @input="selectSelectedDokter($event)" v-model="isParent.row.arres_jadwal_dokter_id"
                    :options="isParent.mDokter" :disabled="(isParent.row.arres_poli && isParent.row.arres_waktu_periksa)?false:true" label="text" :clearable="true"
                    :reduce="v=>v.value">
                    </v-select>
                    <VValidate 
                        name="Dokter" 
                        v-model="isParent.row.arres_jadwal_dokter_id" 
                        :rules="toValidate(isParent.mrValidation.arres_jadwal_dokter_id)"
                    />

                    <span class="text-warning" v-if="Object.keys(selectedDokter||{}).length">Kuota Dokter Saat Ini {{selectedDokter.daily_kuota||0}} </span>

                    <span class="text-warning" v-if="isParent.row.arres_poli && !(isParent.mDokter||[]).length"> Tidak Ada Jadwal Dokter </span>

                    
                </div>

                <div class="form-group col-md-3" v-if="isParent.row.arres_type != 'Poli' || isParent.row.arres_jenis_penunjang == 'FISIO'">
                    <label>Jenis Penunjang<small class="txt_mandatory">*</small></label>
                    <v-select placeholder="Penunjang" v-model="isParent.row.arres_jenis_penunjang"
                    :options="isParent.row.arres_jaminan == 3 ? otherOptPenunjang : Config.mr.statusPenunjangOpt" label="text" :clearable="true"
                    :reduce="v=>v.value"></v-select>
                    <VValidate 
                        name="Penunjang" 
                        v-model="isParent.row.arres_jenis_penunjang" 
                        :rules="toValidate(isParent.mrValidation.arres_jenis_penunjang)"
                    />
                </div>
            </div>
            </div>
            <div class="form-group col-md-12">
            <label>Apakah Pasien Menggunakan Alat Bantu? <small class="txt_mandatory">*</small></label>
                <div class="clearfix"></div>
                <b-form-radio-group
                    :options="Config.mr.yesNoOptV2"
                    v-model="isParent.row['arres_is_alat_bantu']"
                    @input="isParent.row.arres_alat_bantu=null"
                />
                <VValidate 
                    name="Pasien Menggunakan Alat Bantu" 
                    v-model="isParent.row.arres_is_alat_bantu" 
                    :rules="{required : 1}"
                />
            </div>

            <div class="col-12" v-if="isParent.row.arres_is_alat_bantu == 'Y'">
            <div class="row">
                <div class="form-group col-md-4">
                <label>Alat Bantu<small class="txt_mandatory">*</small></label>
                <v-select placeholder="Alat Bantu" v-model="isParent.row.arres_alat_bantu"
                    :options="isParent.mAlatBantu" label="text" :clearable="true"
                    :reduce="v=>v.value"></v-select>
                <VValidate 
                    name="Alat Bantu" 
                    v-model="isParent.row.arres_alat_bantu" 
                    :rules="toValidate(isParent.mrValidation.arres_alat_bantu)"
                />
                </div>
            </div>
            </div>

            <div class="col-12 mt-2" v-if="(isParent.listDataPenunjang||[]).length">
                <h6>Tindakan penunjang</h6>
                <table class="table table-bordered table-striped table-hover table-sm text-uppercase">
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>Nama Tindakan</th>
                            <th>Jenis Tindakan</th>
                            <th>Tanggal Tindakan</th>
                            <th>Aksi</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(v,k) in (isParent.listDataPenunjang||[])" :key="k">
                            <td>{{k+1}}</td>
                            <td>{{v.tindakan||"-"}}</td>
                            <td>{{v.type == "RADIO" ? "Radiologi" : "Laboratorium"}}</td>
                            <td>{{v.date | moment("DD MMMM YYYY")}}</td>
                            <td>
                                <template v-if="!isParent.row.ar_reg_code">
                                <b-form-checkbox v-model="v.selected" class="form-check-input-styled" name="radio-inline-left" />
                                </template>
                                <template v-else>
                                    <div class="badge badge-success mb-1" v-if="v.selected">
                                        Sudah Terdaftar
                                    </div>
                                    <div class="badge badge-dark mb-1" v-else>
                                        Belum Terdaftar
                                    </div>
                                </template>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <small class="text-danger">*PASTIKAN ANDA MEMILIH REGISTRASI DENGAN BENAR KARENA DATA TIDAK DAPAT DIUBAH SETELAH DISUBMIT.</small>
            </div>
        </div>
        </div>

    </fieldset>
</template>


<script>
import Config from '@/libs/Config'
import Gen from '@/libs/Gen.js'
const _ = global._
import Datepicker from 'vuejs-datepicker'
const moment = require('moment')

export default {
    computed: {    
        Config(){ return Config },
        isParent(){
            return this.$parent.$parent.$parent.$parent
        },
        disabledDates() {
            let datesDisabled = []
            for (let i = 0; i < this.isParent.mHariLibur.length; i++) {
               datesDisabled.push(new Date(moment(this.isParent.mHariLibur[i]['mhl_date'])))
            }
            return {
                to: this.isParent.row.arres_start_kontrol ? new Date(moment(this.isParent.row.arres_start_kontrol).format('YYYY-MM-DD')) : new Date(moment().format('YYYY-MM-DD')),
                dates: datesDisabled,
                days: [0],
            }
        },
        otherOptPenunjang(){
            let data = []
            if(!this.isPoliAdj){
                data = [
                    {'text':'Fisioterapi','value':'FISIO'}
                ]
            }else{
                data = [
                    {'text':'Fisioterapi','value':'FISIO'}
                ]
                if(this.isParent.isShowRadiologi){
                    data.push({'text':'Radiologi','value':'RADIO'})
                }
                if(this.isParent.isShowLab){
                    data.push({'text':'Laboratorium','value':'LAB'})
                }
            }
            return data
        },

        // ada adjustment
        isPoliAdj(){
            return this.isParent.$parent.isPoliAdj
        },
        // ada adjustment
    },
    data(){
        return{
            selectedDokter : {}
        }
    },
    components: {
      Datepicker
    },
    mounted() {
        setTimeout(()=>{
            this.selectSelectedDokter(this.isParent.row.arres_jadwal_dokter_id)
        },1500)
    },
    methods: {
        toValidate(val){
            return {...val}
        },
        selectDokter(poli,waktuPeriksa,tglPeriksa) {
            this.isParent.row.arres_dokter = null
            this.isParent.row.arres_jadwal_dokter_id = null
            this.selectedDokter = {}
            this.isParent.$parent.loadingOverlay = true
           
            let data = {
                id: poli,
                waktuPeriksa: waktuPeriksa,
                tgl_periksa: tglPeriksa,
                type: 'get-by-poli'
            }
            Gen.apiRest(
            "/do/" + 'RoReservasi', {
                data: data
            },
            "POST"
            ).then(res => {
                this.isParent.$parent.loadingOverlay = false
                this.isParent.mDokter = res.data.mDokter
            })
        },
        selectSelectedDokter(e){
            let index = this.isParent.mDokter.findIndex(x => x.value == e)
            if (index !== -1) {
                this.selectedDokter = this.isParent.mDokter[index]
                this.isParent.row.arres_dokter = this.selectedDokter.dokter_id
            }
            
        },
        checkDateIsLibur(e){
            let year = moment(e, "YYYY").format('YYYY')
            
            let data = {
                year: year,
                type: 'get-holiday-config'
            }
            Gen.apiRest(
                "/do/" + 'RoReservasi', {
                    data: data
                },
                "POST"
            ).then(res => {
                this.isParent.mHariLibur = res.data.mHariLibur
            })
        }
    }
}
</script>
